<!-- eslint-disable -->
<style lang="scss">
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  max-width: 600px;
  height: fit-content;
  max-height: 300px;
  display: block;
}

.loader-content {
  padding-top: 96px;
  position: relative;
  top: 100%;
  bottom: 0;
}

.p-body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: white;
  margin: 0;
  text-align: center;
}

#dot1,
#dot2,
#dot3,
#dot4,
#dot5,
#dot6,
#dot7,
#dot8,
#dot9,
#dot10 {
  position: absolute;
  left: 30%;
  width: 10px;
  height: 10px;
  border-radius: 50px 50px;
  background: #007aff;
  animation: spin 1.5s linear infinite;
  transform-origin: center center;
}

#dot2 {
  animation: spin 1.5s -0.75s linear infinite;
}

#dot3 {
  left: 38%;
  animation: spin 1.5s -1.3s linear infinite;
}

#dot4 {
  left: 38%;
  animation: spin 1.5s -0.55s linear infinite;
}

#dot5 {
  left: 46%;
  animation: spin 1.5s -1.1s linear infinite;
}

#dot6 {
  left: 46%;
  animation: spin 1.5s -0.35s linear infinite;
}

#dot7 {
  left: 54%;
  animation: spin 1.5s -0.9s linear infinite;
}

#dot8 {
  left: 54%;
  animation: spin 1.5s -0.15s linear infinite;
}

#dot9 {
  left: 62%;
  animation: spin 1.5s -0.7s linear infinite;
}

#dot10 {
  left: 62%;
  animation: spin 1.5s 0.05s linear infinite;
}

#base1,
#base2,
#base3,
#base4,
#base5 {
  position: absolute;
  top: 30px;
  left: 31.5%;
  width: 1px;
  height: 10px;
  background: #007aff;
  animation: flex 1.5s linear infinite;
  transform-origin: center center;
}

#base2 {
  left: 39.5%;
  animation: flex 1.5s -1.3s linear infinite;
}

#base3 {
  left: 47.5%;
  animation: flex 1.5s -1.1s linear infinite;
}

#base4 {
  left: 55.5%;
  animation: flex 1.5s -0.9s linear infinite;
}

#base5 {
  left: 63.5%;
  animation: flex 1.5s -0.7s linear infinite;
}

@keyframes spin {

  0%,
  100% {
    transform: translateY(0px) scale(1);
  }

  25% {
    transform: translateY(30px) scale(2);
  }

  50% {
    transform: translateY(60px) scale(1);
    background: rgba(0, 0, 0, 0.95);
    background: #1f1f1f;
  }

  75% {
    transform: translateY(30px) scale(0.3);
  }

}

@keyframes flex {

  0%,
  100% {
    transform: scaleY(5);
  }

  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(5);
    background: rgba(0, 0, 0, 0.95);
    background: #1f1f1f;
    opacity: 0;
  }

  75% {
    transform: scaleY(1);
  }

}

.ww {
  word-wrap: break-word;
}

.v-expansion-panel-header__icon {
  margin-top: 1px !important;
}

#app .o-table-analisys .v-expansion-panel-header {
  align-items: start !important;

  .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    padding-top: 5px !important;
  }
}

.v-expansion-panel-content {
  margin-top: -14px !important;
}

.p__clase {
  // margin-left: 23px;
  // padding-bottom: 7px;
  margin-bottom: 0 !important;
}

.align-items-self-start {
  align-items: flex-start;
}

#app .c-dropdown {
  .c-dropdown__p {
    padding: 0.45rem 0 0 0 !important;
    font-size: 14px !important;
  }
}

.n-g {
  position: absolute;
  width: 100%;
  height: 2.5rem;
  background-color: white;
  z-index: 5;
  top: 0px;
  align-items: center;
}

.mdi-close::before {
  content: "\F0156";
  position: absolute;
  bottom: -11px;
  /* left: 2px; */
  right: 0px;
}

#app .c-table-analisys .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  // border-top: 1px solid #ADADAD !important;
  // text-align: left !important;
}

#app .c-table-analisys .table-th.td-pocillo,
#app .c-table-analisys .table-td.td-pocillo {
  width: 10% !important;
}

#app {
  .o-analisis-page {
    .v-card__text {
      // padding-bottom: 0!important;
    }
  }

  .o-kit-pcr {
    .v-text-field .v-input__append-inner {
      //margin-top: 0;
    }
  }
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  // min-height: 36px;
  min-height: initial;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control {
  min-height: 30px;
}

.c-red {
  color: #F00;
  line-height: 15px;
}

.border-select .v-input__control {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

#app .border-select.v-text-field--outlined fieldset {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.border-select .v-input__slot {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.border-select .v-text-field--outlined fieldset {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

#app .o-analisis-page h3 {
  font-weight: 500;
}

#app .c-table-analisys .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  font-weight: 700 !important;
  // text-transform: capitalize;
  font-size: 14px !important;
}

#app .o-table-analisys .table-th,
#app .o-table-analisys .table-td {
  border-left: 0px solid transparent !important;
}

#app .c-table-analisys .table-th,
#app .c-table-analisys .table-td {
  box-shadow: none !important;
}

#app .o-table-analisys .table-th:last-child,
#app .o-table-analisys .table-td:last-child {
  border-right: 0px solid transparent !important;
}

#app .o-table-analisys .table-th,
#app .o-table-analisys .table-td {
  border-top: 0px solid transparent !important;
  border-bottom: 0px solid transparent;
}

#app .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
#app .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
  background-color: white !important;
}

.blueee {
  color: #344C98 !important;
}

.mt4-b2 {
  margin-top: 39px !important;
  margin-bottom: 1rem;
}

.fs13-100 {
  font-weight: 500 !important;
  font-size: 13px !important;
}

.mx-26 {
  margin-left: -2px;
  margin-right: 26px;
}

.pr-70 {
  padding-right: 70px;
}

.txtspan2 {
  font-weight: 500 !important;
}

#app .v-sheet.v-card:not(.v-sheet--outlined) {
  // z-index: 10;
}

.mt2 {
  margin-top: 26px;
}
</style>
<template>
  <!-- eslint-disable -->
  <div class="container-fluid o-analisis-page">
    <v-snackbar v-model="snack.snackbar" :vertical="snack.vertical" :color="snack.color" :right="true" :top="true">
      <div v-if="snack.color == 'red'">
        {{ snack.txt }}
      </div>
      <div v-else class="c-snackbar__active">
        <svg class="mr-4" width="18.021" height="17.778" viewBox="0 0 18.021 17.778">
          <g id="check-circle" transform="translate(-1.039 -1.074)">
            <path id="Trazado_1" data-name="Trazado 1" d="M18,9.262V10a8,8,0,1,1-4.744-7.312"
                  transform="translate(0 0)" fill="none" stroke="#007aff" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1.5" />
            <path id="Trazado_2" data-name="Trazado 2" d="M19.13,4l-7.793,7.8L9,9.463"
                  transform="translate(-1.131 -0.443)" fill="none" stroke="#007aff" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1.5" />
          </g>
        </svg>
        {{ snack.txt }}
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" :color="snack.colorbtn" @click="snack.snackbar = false">{{
          $t('menu.Dismiss')
          }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="bg--light__gray">
      <v-card-text>
        <div v-if="analizar" style="padding: 22px 22px 0px;">
          <v-row class="">
            <v-col class="mt1 text-left" cols="12">
              <p class="mt-1 fs20 mb-3 mr-1 bold">{{ $t('analisis.txt_1') }}</p>
              <p class="fs16 mt-1 mb-2 mr-1">{{ $t('analisis.txt_2') }}</p>
            </v-col>
          </v-row>
          <v-row class="pt-5">
            <v-col cols="12" class="pt-5">
              <div class="d-flex align-items-center">

                <svg width="20" height="20" viewBox="0 0 28 28">
                  <g id="Grupo_3815" data-name="Grupo 3815" transform="translate(-418 -410)">
                    <g id="Elipse_146" data-name="Elipse 146" transform="translate(418 410)"
                       fill="#fff" stroke="#057aff" stroke-width="1.5">
                      <circle cx="14" cy="14" r="14" stroke="none" />
                      <circle cx="14" cy="14" r="13.25" fill="none" />
                    </g>
                    <text id="_1" data-name="1" transform="translate(427.5 430.988)" fill="#1a1a1a"
                          font-size="18" font-family="OpenSans-Regular, Open Sans">
                      <tspan x="0" y="0">1</tspan>
                    </text>
                  </g>
                </svg>

                <p class="fs16 bold ml-2 mb-0">{{ $t('analisis.txt_4') }}</p>
              </div>
            </v-col>
            <v-radio-group
              v-model="radios"
              row
              @change="changeRadios($event)"
              class="px-3 mt-3">
              <!--
                           <v-radio

                              class="fs16"
                              :label="$t('analisis.txt_5')"
                              :value="1"
                              :checked="radios == 1"
                              >
                          </v-radio>
                          <v-radio

                              class="fs16"
                              :label="$t('analisis.txt_6')"
                              :value="2"
                              :checked="radios == 2"
                             >
                          </v-radio>

  --->
              <v-radio

                class="fs16"
                :label="$t('analisis.bitacora')"
                :value="3"
                :checked="radios == 3"
              >
              </v-radio>
              <v-radio

                class="fs16"
                :label="$t('analisis.analisis_sin_muestra')"
                :value="4"
                :checked="radios == 4"
              >
              </v-radio>
            </v-radio-group>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex align-items-center">

                <!-- <svg width="20" height="20" viewBox="0 0 28 28">
                                    <g id="Grupo_3815" data-name="Grupo 3815" transform="translate(-418 -410)">
                                        <g id="Elipse_146" data-name="Elipse 146" transform="translate(418 410)"
                                            fill="#fff" stroke="#057aff" stroke-width="1.5">
                                            <circle cx="14" cy="14" r="14" stroke="none" />
                                            <circle cx="14" cy="14" r="13.25" fill="none" />
                                        </g>
                                        <text id="_2" data-name="2" transform="translate(427.5 430.988)" fill="#1a1a1a"
                                            font-size="18" font-family="OpenSans-Regular, Open Sans">
                                            <tspan x="0" y="0">2</tspan>
                                        </text>
                                    </g>
                                </svg>-->

                <p class="fs16 bold ml-2 mb-0">{{ $t('analisis.txt_3') }}</p>
              </div>
            </v-col>
            <!--<v-col cols="12" md="12" sm="12" class="">
                            <v-radio-group
                        row
                        mandatory
                        v-model="tipoBitacora"
                        @change="getEnsayosOrProgram()"
                        >
                        <v-radio
                            :label="'analisis'"
                            value="1"
                        ></v-radio>
                        <v-radio
                            :label="'programa'"
                            value="2"
                        ></v-radio>
                        </v-radio-group>
                        </v-col>-->

            <v-col v-if="radios == 2 || radios == 1 "
                   cols="6"
                   md="5"
                   sm="12"
                   class="">
              <v-select
                v-model="dataForm.kitPcr"
                :items="kitPCR" item-text="name"
                item-value="id"
                :placeholder="$t('analisis.selecciona_equipo')"
                @change="activarMuestras($event)"
                append-icon="mdi-chevron-down"
                outlined
                dense>
              </v-select>
            </v-col>
            <!-- return-object agregar atributo para mandar siempre esl obejto y cambiarlo con ueva funcion -->
          </v-row>
          <v-row>
            <v-col
              cols="6" md="5" sm="12" class="">
              <v-select
                class="select-height"
                v-model="dataForm.equipoKit"
                :items="filterEq()"
                item-text="equipo"
                item-value="id"
                :placeholder="$t('analisis.Selecciona')"
                append-icon="mdi-chevron-down"
                @change="activaItemspocillos($event)"
                outlined
                dense></v-select>
            </v-col>
            <!-- return-object agregar atributo para mandar siempre esl obejto y cambiarlo con ueva funcion -->
          </v-row>
          <div v-if="radios == 2 || radios == 3" class="mt-3">
            <v-row v-if="this.infoUser.externo == 0">
              <v-col class="pt-0 dflex" cols="6" md="5" sm="12">
                <v-text-field

                  class="border-select w440"
                  :placeholder="$t('analisis.ingresa')"
                  v-model="dataForm.OT"
                  type="number"
                  onkeydown="return
                                    event.keyCode !== 69"
                  v-on:keyup.enter="buscarOT"
                  min="1"
                  outlined dense>
                </v-text-field>
                <v-btn
                  :disabled="disabledBtnOtSearch"
                  style="padding: 1.1rem 1rem !important;"
                  class="btnot" color="c-btn--black"
                  @click="buscarOT()"
                  :loading="loadot">
                  {{ $t('analisis.revisar_ot') }}
                </v-btn>
              </v-col>
              <!-- <v-row class=""> -->
              <!-- </v-row> -->
            </v-row>
          </div>
          <v-row v-if="maxFiles > 0">
            <v-col cols="6" md="5" sm="12" class="text-left py-0 my-0">
              <span>{{ this.$t('analisis.must_select') }} <span class="font-weight-bold">{{ maxFiles }}</span> {{this.$t('analisis.files')}}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="5" sm="12" class="c-pointer o-file-search">
              <v-file-input v-model="selectedFiles" :placeholder="$t('analisis.carga')"
                chips
                            :disabled="maxFiles === 0"
                :multiple="maxFiles > 1"
                :max="maxFiles"
                :accept="accept_txt" prepend-icon="" @change="checkFile()"
                outlined :clearable="true" class="o-file-search__input">
              </v-file-input>
              <span class="z999  o-file-search__icon2">
                  <svg width="19.5" height="19.5" viewBox="0 0 19.5 19.5">
                      <g id="upload_2_" data-name="upload (2)" transform="translate(0.75 0.75)">
                          <path id="Trazado_8521" data-name="Trazado 8521"
                                d="M21,15v4a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V15" transform="translate(-3 -3)"
                                fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="1.5" />
                          <path id="Trazado_8522" data-name="Trazado 8522" d="M17,8,12,3,7,8"
                                transform="translate(-3 -3)" fill="none" stroke="#fff"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                          <line id="Línea_11" data-name="Línea 11" y2="12" transform="translate(9)"
                                fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="1.5" />
                      </g>
                  </svg>
              </span>
            </v-col>
            <v-col
              cols="6" md="5" sm="12" class="pb-3">
            </v-col>
            <v-col v-if="radios == 4"
                   cols="6"
                   md="5"
                   sm="12"
                   class="">
              <v-select
                v-model="dataForm.kitPcr"
                :items="filterProgram()" item-text="name"
                item-value="id"
                :placeholder="$t('analisis.selecciona_equipo')"
                @change="activarMuestras($event)"
                append-icon="mdi-chevron-down"
                outlined
                dense>
              </v-select>
            </v-col>
            <v-col v-if="radios == 2 || radios == 3 " class="pr-3 dflex btn-analisis c-botones text-left">
              <v-btn depressed color="#007AFF" class="bold c-btn white--text"
                     @click="analizarSamples();" :loading="loadingResponse"
                     :disabled="activarAnalisis">{{ $t('analisis.analizar_resultados') }}
              </v-btn>
            </v-col>
            <v-col v-if="radios == 4" class="pr-3 dflex btn-analisis c-botones text-left">
              <v-btn depressed color="#007AFF" class="bold c-btn white--text"
                     @click="analizarSamples();" :loading="loadingResponse"
                     :disabled="analyzeIsDisabled()">{{ $t('analisis.analizar_resultados') }}
              </v-btn>
            </v-col>
            <v-col v-if="radios == 1" class="pr-3 dflex btn-analisis c-botones text-left">
              <v-btn depressed color="#007AFF" class="bold c-btn white--text"
                     @click="analizarSamples();" :loading="loadingResponse"
                     :disabled="activarAnalisis">{{ $t('analisis.analizar_resultados') }}
              </v-btn>
              asasd
            </v-col>
          </v-row>
          <div class="mt-4 bg--normal__white border--radius__10 p-tabla">
            <v-row>
              <v-col class="pt-0" cols="12" md="2">
                <div class="d-flex align-items-center">
                  <div class="tooltip1">
                    <v-icon class="iconq" color="secondary" dark>
                      mdi-information-outline
                    </v-icon>
                    <span class="tooltiptext">{{ $t('analisis.toolp') }}</span>
                  </div>
                  <dropdown class="c-list-dropdown" :close-on-click="true">
                    <template slot="btn" class="pl-1 c-list-dropdown__btn">{{
                      $t('analisis.acciones')
                      }}</template>
                    <template slot="body">
                      <ul>
                        <li>
                          <dropdown :trigger="'hover'" :role="'sublist'" :align="'right'">
                            <template slot="btn">{{
                              $t('analisis.aplicar_matriz')
                              }}</template>
                            <template slot="body">
                              <ul class="c-list-dropdown__ul">
                                <li v-for="(item, index) in matrices" :key="item.id"
                                    @click="aplicarmatriz(item)">
                                  {{ getlang(item.matriz) }}
                                </li>
                              </ul>
                            </template>
                          </dropdown>
                        </li>
                        <li>
                          <dropdown :trigger="'hover'" :role="'sublist'" :align="'right'">
                            <template slot="btn">{{ $t('analisis.sub_matriz') }}</template>
                            <template slot="body">
                              <ul class="c-list-dropdown__ul">
                                <li v-for="(item, index) in kitMatrices" :key="item.id"
                                    @click="aplicarKit(item)">
                                  {{ item.kit }}
                                </li>
                              </ul>
                            </template>
                          </dropdown>
                        </li>
                        <li>
                          <dropdown :trigger="'hover'" :role="'sublist'" :align="'right'">
                            <template slot="btn">{{ $t('analisis.estado') }}</template>
                            <template slot="body">
                              <ul class="c-list-dropdown__ul">
                                <li v-for="(item, index) in deterioro" :key="item.id"
                                    @click="aplicarDeterioro(item)">
                                  {{ getlang(item.name) }}
                                </li>
                              </ul>
                            </template>
                          </dropdown>
                        </li>

                        <!-- categoria_kit_ensayos -->
                        <li v-if="categoria_kit_ensayos_filtered.length > 0" id="hide">
                          <dropdown :trigger="'hover'" :role="'sublist'" :align="'right'">
                            <template slot="btn">{{ nameCategoriaKitEnsayos }}</template>
                            <template slot="body">
                              <ul>
                                <li v-for="(item, index) in categoria_kit_ensayos"
                                    :key="item.id"
                                    @click="aplicarCategoriaKitEnsayos(item)">
                                  {{ getlang(item.nombre) }}
                                </li>
                              </ul>
                            </template>
                          </dropdown>
                        </li>
                        <!-- categoria_kit_ensayos -->
                      </ul>
                    </template>
                  </dropdown>
                </div>
              </v-col>
            </v-row>
            <div class=" border--bottomGray">
              <v-row>
                <v-col class="pt-0" cols="12 c-table-analisys">
                  <template>
                    <v-simple-table dense fixed-header >
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left table-th td-name">
                            <v-checkbox class="dn"
                                        color="primary"
                                        @click="checkAll($event)"
                                        v-model="check"
                                        :disabled="activeinput == 0">
                            </v-checkbox>
                            <p class="mb-0 px-4">
                              {{ $t('analisis.well') }}
                            </p>
                          </th>
                          <th class="fw100 table-th td-matrix">
                            <p class="mb-0 px-4">
                              {{ $t('analisis.medio') }}
                            </p>
                          </th>
                          <th class="fw100 table-th td-code">
                            <p class="mb-0 px-4">
                              {{ $t('analisis.codigo_muestra') }}
                            </p>
                          </th>
                          <th class="fw100 table-th td-code">
                            <p class="mb-0 px-4">
                              {{ $t('analisis.ensayo') }}

                            </p>
                          </th>
                          <th class="fw100 table-th td-matrix">
                            <p class="mb-0 px-4">
                              {{ $t('analisis.matriz') }}
                            </p>
                          </th>
                          <th class="fw100 table-th td-matrix">
                            <p class="mb-0 px-4">
                              {{ $t('analisis.categoria') }}
                            </p>
                          </th>
                          <th class="fw100 table-th td-matrix">
                            <p class="mb-0 px-4">
                              Mix
                            </p>
                          </th>
                          <th class="fw100 table-th td-percentage">
                            <p class="mb-0 px-4">
                              {{ $t('analisis.estado_muestra') }}
                            </p>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in sampleList" :key="item.name">

                          <td class="text-start table-td td-name td-pocillo">
                            <div
                              class="w-100 align-items-center justify-content-space-evenly d-flex">
                              <v-checkbox color="primary" v-model="item.checked"
                                          :disabled="false"></v-checkbox>
                              <span>
                                                                    <!-- {{ item.pocillo }} {{((item.namePocillo != '')
                                                                ? ((dataForm.equipoKit == 2 || dataForm.equipoKit
                                                                    == 3) ? '(' + item.namePocillo + ')' : '') : '')}} -->
                                                                    {{item.namePocillo}}
                                                                </span>
                            </div>
                          </td>
                          <!-- nuevo campo anañisis -->
                          <td class="text-start table-td td-code">
                            <v-select :items="categoria_kit_ensayos"
                                      :placeholder="$t('analisis.medioSelect')"
                                      v-model="item.categoria_kit_ensayo_id" :disabled="true"
                                      item-value="id" outlined dense
                                      append-icon="mdi-chevron-down">
                              <template v-slot:selection="{ item }">
                                <div class="o-select__option">
                                  {{ getlang(item.nombre) }}
                                </div>
                              </template>

                              <template v-slot:item="{ item }">
                                <div class="o-select__option">
                                  {{ getlang(item.nombre) }}
                                </div>
                              </template>
                            </v-select>
                          </td>
                          <!-- fin nuevo campo analisis -->
                          <td class="text-start table-td td-code">
                            <v-text-field type="number"
                                          onkeydown="return event.keyCode !== 69"
                                          v-model="item.codesample"
                                          :placeholder="$t('analisis.ingresa_codigo')"
                                          @change="buscarMuestra(item, index)"
                                          :disabled="activeinput == 0" required
                                          :error="item.color == 'red'"
                                          :success="item.color == 'green'" solo autocomplete="off"
                                          outlined dense class="text-align-last-center">
                            </v-text-field>
                          </td>
                          <td class="text-start table-td td-code">
                            <v-text-field type="text"
                                          onkeydown="return event.keyCode !== 69"
                                          v-model="item.ensayo_name"
                                          :placeholder="$t('analisis.ensayo')"
                                          :disabled="true" required
                                          :error="item.color == 'red'"
                                          :success="item.color == 'green'" solo autocomplete="off"
                                          outlined dense class="text-align-last-center">
                            </v-text-field>
                          </td>
                          <td class="text-start table-td td-matrix">
                            <v-select :items="matrices"
                                      :placeholder="$t('analisis.seleccionar_matriz')"
                                      v-model="item.matriz_id"
                                      :disabled="(item.matriz_id != null && item.info_muestra) ? true : false"
                                      item-value="id" item-text="matriz" outlined dense
                                      append-icon="mdi-chevron-down"
                                      @change="updatelist(item, $event)"></v-select>
                          </td>

                          <td class="text-start table-td td-matrix">
                            <v-select :items="kitMatrices"
                                      :placeholder="$t('analisis.seleccionar_kit')"
                                      v-model="item.kit" :disabled="disableSelect(item)"
                                      item-value="id" item-text="kit" outlined dense
                                      append-icon="mdi-chevron-down"
                                      @change="updatematriz(item, $event)"></v-select>

                          </td>
                          <td class="text-start table-td td-matrix">
                            <v-text-field type="text"
                                          onkeydown="return event.keyCode !== 69"
                                          v-model="item.mixName"
                                          :placeholder="'Mix'"

                                          :disabled="true" required
                                          :error="item.color == 'red'"
                                          :success="item.color == 'green'" solo autocomplete="off"
                                          outlined dense class="text-align-last-center">
                            </v-text-field>

                          </td>
                          <td class="text-start table-td td-matrix">
                            <v-select :items="deterioro" v-model="item.deteriorado"
                                      :disabled="disableSelect(item)" item-text="name"
                                      item-value="id" append-icon="mdi-chevron-down"
                                      :placeholder="$t('analisis.seleccionar_status')"
                                      outlined dense></v-select>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>
              </v-row>
            </div>
            <!-- <v-row class="">
                            <v-col class="pr-3 dflex btn-analisis c-botones text-left">
                                <v-btn depressed color="#007AFF" class="bold c-btn white--text"
                                    @click="analizarSamples();" :loading="loadingResponse"
                                    :disabled="activarAnalisis">{{ $t('analisis.analizar_resultados') }}
                                </v-btn>
                            </v-col>
                        </v-row> -->
          </div>
        </div>

        <div v-else style="padding: 0 22px;">
          <v-row class="">
            <v-col cols="12">
              <!--<h3 class="ml-3">Resultados SteriPLEX</h3>-->
              <p class="mt2 mr-1 bold fs20 text-left">{{ $t('analisis.Resultados_analisis') }}</p>
              <p class="fs16 mt-3 mr-1 text-left"> {{ $t('analisis.Reviewyouranalysisresultsfor') }}
                <template v-if="radios == 1">
                  {{ n_ensayo }}
                </template>
                <template v-else>
                  <template v-if="radios == 2">
                    {{ $t('analisis._ot') }}
                  </template>
                  <template v-else>
                    {{ $t('analisis.bitacora') }} - <strong># {{ dataForm.OT }}</strong>
                  </template>
                </template>

                <!-- {{ (radios == 1)?n_ensayo:radios==2? $t('analisis._ot'):$t('analisis.bitacora')+' - # '+ dataForm.OT }} -->
              </p>
              <!-- VERSION DEL SOFTWARE: -->
              <!--<p class="fs16 mt-3 mr-1 text-left">{{ ubication_simple }}</p>-->
            </v-col>
          </v-row>
          <v-row class="bg--normal__white border--radius__10">
            <v-col cols="12" class=" o-table-analisys c-table-analisys">
              <v-alert dense text  v-if="completedBitacora !== null"
                       :type="completedBitacora === 2 ? 'success' : (completedBitacora === 1 ? 'warning' : 'info') ">
                {{completedBitacora === 2 ? $t('analisis.bitacora_publicada') : (completedBitacora === 1 ? $t('analisis.bitacora_publicada_parcialmente') : $t('analisis.bitacora_publicada'))}}
              </v-alert>
              <v-alert dense text type="success" v-if="publicsample == true">
                {{ $t('analisis.muestras_publicadas') }} <strong>{{
                  $t('analisis.muestras_publicadas_txt1')
                }}</strong>
                {{ $t('analisis.muestras_publicadas_txt2') }} <strong>Txa-Lab</strong>
              </v-alert>
              <!--<v-alert dense text type="error" v-if="errorsample == true">
                Las siguientes muestras no se han publicado por errores:
                <strong v-for="(err, index) in errors">
                  {{ err.muestra }} ({{ err.pato }})<span v-if="(index + 1) < errors.length">,</span>
                </strong>
              </v-alert>-->

              <v-alert dense text type="error" v-if="errorsample == true">
                {{ $t('analisis.ocurrio_error_publicar') }}
              </v-alert>
              <!-- cuando no hay ninguna muestra a publicar -->
              <v-alert dense dismissible border="left" elevation="2" type="error"
                       v-if="nadaApublicar == true">
                {{ $t('analisis.sin_ingresar_muestra') }} <strong>TXA-LAB</strong>
                {{ $t('analisis.sin_ingresar_muestra_txt1') }}
              </v-alert>

              <!-- alert cuando no se encuentran parametros que e estan enviando -->
              <v-alert dense dismissible border="left" elevation="2" type="warning"
                       v-for="item in parametroIndefinido"
                       :key="item.pocillo + '_' + item.muestra + '_' + item.parametro">
                {{ $t('analisis.el_param') }} <strong>{{ item.parametro }}</strong> {{
                $t('analisis.el_param_txt1')
                }}
                <strong>{{ item.muestra }}</strong> {{ $t('analisis.el_param_txt2') }} (<strong>{{item.namePocillo}}</strong>)
              </v-alert>

              <v-alert dense dismissible border="left" elevation="2"
                       v-for="(item, indexWarn) in warningsPublicar" v-if="item.type === 'WARN'" type="warning"
                       :key="indexWarn">
                    {{showTextWarning(item)}}
              </v-alert>

              <!-- alert para muestras que tienen inhibicion como primer lugar -->
              <v-alert dense dismissible border="left" elevation="2" type="error"
                       v-for="item in muestrasNopublicadas" :key="'SI' + item.muestra">
                {{ $t('analisis.la_muestra') }} <strong>{{ item.codesample }}</strong> {{
                $t('analisis.la_muestra_txt1')
                }}
                <strong>{{ item.Bac_1 }}</strong>,
                {{ $t('analisis.la_muestra_txt2') }}
              </v-alert>

              <!-- fin de alertas -->
              <template>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <!-- <th >
                                                    <v-checkbox
                                                        color="success"
                                                        hide-details
                                                        @click="checkAllpublish($event)"
                                                        v-model="checkedAllpublish"
                                                    ></v-checkbox>
                                                </th> -->
                      <th class="fs13-100 text-left table-th td_result--name">
                        {{ $t('analisis.pocillo') }}
                      </th>
                      <th class="fs13-100 text-left table-th td-percentage">
                        {{ $t('analisis.tipo') }}
                      </th>
                      <!-- nuevo campo para funcion del poool categoria de cada campo -->
                      <th class="fs13-100 text-left table-th td-percentage">
                        {{ $t('analisis.medio') }}
                      </th>
                      <th class="fs13-100 text-left table-th td-percentage">
                        Mix
                      </th>
                      <th class="fs13-100 text-center table-th td_result--muestra">
                        {{ $t('analisis.muestra') }}
                      </th>
                      <th class="fs13-100 text-center table-th td_result--matriz">
                        {{ $t('analisis.matriz') }}
                      </th>
                      <th class="fs13-100 text-center table-th td_result--desc">
                        {{ $t('analisis.descripcion') }}
                      </th>
                      <th class="fs13-100 text-left table-th td_result--result" >
                        <div class="td_result--result-container" >
                                                        <span class="td_result--result-container-title">
                                                            {{ $t('analisis.resultado') }}
                                                        </span>
                          <!-- switch para editar seultado -->
                          <v-switch
                            class="td_result--result-container-switch"
                            v-if="(kitEjecutar.software.kit == 'food' && radios !== 4) && (bitAuthuser.bitauth_userdatum.rol_id == 3 || bitAuthuser.bitauth_userdatum.rol_id == 4 || bitAuthuser.bitauth_userdatum.rol_id == 6)"
                            v-model="activeEdit"
                            :label="$t('ensayos.editar')"
                            color="info"
                            hide-details
                            @change="enableResultEdit($event)"
                          ></v-switch>
                        </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in sampleList" :key="item.name">
                      <!-- <td >
                                                    <v-checkbox
                                                        v-model="item.publicar"
                                                        color="success"
                                                        hide-details
                                                    ></v-checkbox>
                                                </td> -->
                      <td class=" text-start table-td border--bottomGray">
                        <span class="mb-0 fs14">
                            <!-- {{ item.pocillo }} {{((item.namePocillo != '')
                            ? ((dataForm.equipoKit == 2) ? '(' + item.namePocillo + ')' : '') :
                            '')}} -->
                            {{item.namePocillo}}
                        </span>
                      </td>
                      <td>
                        <p class="mb-0 fs14" v-if="item.matriz != 'Otro'">{{ getTipo(item) }}</p>
                        <p v-else></p>
                      </td>
                      <!-- COLUMNA PARA EL PREFIJO QUE ES  -->
                      <td class=" table-td  border--bottomGray">
                        <p class="mb-0 fs14">{{ getPrefijonombre(item) }}</p>
                      </td>
                      <td class="text-center  table-td  border--bottomGray">
                        <p class="mb-0 fs14" v-if="item.mixName">{{ item.mixName }}</p>
                        <p v-else class="mb-0 fs14">--</p>
                      </td>
                      <td class="text-center  table-td  border--bottomGray">
                        <p class="mb-0 fs14" v-if="item.codesample">{{ item.codesample }}</p>
                        <p v-else class="mb-0 fs14">--</p>
                      </td>
                      <!--<td class="text-center  table-td  border--bottomGray">
                                                    <p class="mb-0 fs14" v-if="item.ensayo_name">{{ item.ensayo_name }}</p>
                                                    <p v-else class="mb-0 fs14">--</p>
                                                </td>-->
                      <td class=" text-center table-td  border--bottomGray">
                        <p class="mb-0 fs14" v-if="item.matriz != 'Otro'">
                          {{ getlang(item.matriz) }}</p>
                        <p class="mb-0 fs14" v-else>{{ $t('analisis.otro') }}</p>
                      </td>
                      <td class="table-td  border--bottomGray">
                        <p class="mb-0 fs14" v-if="item.codesample">
                          {{(item.info_muestra.descripcion) ?
                          item.info_muestra.descripcion : '--'}}</p>
                        <p class="mb-0 fs14" v-else>--</p>
                      </td>
                      <td class="text-center table-td  border--bottomGray">
                        <div v-if="kitEjecutar.software.kit == 'food'">
                          <!-- CREAMOS NUEVO COMPONENTE PARA MOSTRAR RESULTADO -->
                          <dropdownResultados ref="resultadosSoftare"  v-bind:dataItem="item" v-bind:dataEnsayo="ensayoSeleccionado"></dropdownResultados>
                          <!-- FIN DE NUEVO COMPONENTE -->
                        </div>
                        <div v-else>
                          <v-expansion-panels v-model="panels[index]" multiple class="c-dropdown">
                            <v-expansion-panel class="c-dropdown__panel">
                              <v-expansion-panel-header class="c-dropdown__header" >
                                <div class="c-dropdown__text"
                                     v-for="(v) in arrayinicial"
                                >
                                  <p class="d-flex align-items-flex-start fs14 c-dropdown__p"
                                     v-if="checkShowResultSteriplex(item,v)"
                                  >
                                    <div v-if="getclase(item)" >
                                      <div class="d-flex align-items-center pb-2">

                                        <div v-if="getclase(item) == cat_riesgo[0].value" >
                                          <img class="mr-2" width="15" src="img/minus-green.png">
                                        </div>
                                        <div v-else >
                                          <img class="mr-2" width="15" src="img/plus-red.png" />
                                        </div>

                                        <p class="p__clase tmp0" :class="( getclase(item) == cat_riesgo[0].value ) ? '' : 'c-red'">
                                          {{ getclase(item, 'clase') }}   {{mostrarClase(item)}}
                                        </p>
                                      </div>


                                    </div>

                                    <div v-else class="n-g d-flex  align-items-self-start m-green">

                                      <img class="mr-2" width="15" src="img/minus-green.png">

                                      <span class="tmp3" v-if="checkIFIsPool(item)" :class="{ 'c-red': getclasebac(item,v) }">
                                                                                    {{getnameBacteria(item[`Bac_${v}`], item, 'tmp3')}}
                                                                                </span>

                                      <span class="tmp4"
                                            :class="{ 'c-red': getclasebac(item,v) }">
                                                                                {{getnameBacteria(item[`Bac_${v}`], item,
                                                                                'tmp4',v)}}  {{(getnameBacteria(item[`Bac_${v}`], item,
                                                                                'tmp4',v) != $t('analisis.vacio'))? getProbHtml(item,v):'' }}
                                                                            </span>
                                    </div>
                                  </p>
                                </div>
                              </v-expansion-panel-header>



                              <v-expansion-panel-content class="c-dropdown__content">

                                <div class="c-dropdown__text" v-for="(v2) in arraycontinua">

                                  <p
                                    v-if="item[`Bac_${v2}`] && item[`Bac_${v2}`] != 'NA'"
                                  >
                                                                            <span class="tmp5" v-if="checkIFIsPool(item)" :class="{ 'c-red': getclasebac(item,v2) }">
                                                                                {{item[`Bac_${v2}`]}}
                                                                            </span>
                                    <span class="tmp6" v-else :class="{ 'c-red': getclasebac(item,v2) }">

                                                                                {{item[`Bac_${v2}`]}}   {{getProbHtml(item,v2) }}
                                                                            </span>


                                  </p>
                                </div>
                              </v-expansion-panel-content>

                            </v-expansion-panel>
                          </v-expansion-panels>
                        </div>

                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn v-if="ocultarBtnPublicar == false" class="c-btn c-btn--blue bold mr-5" outlined @click="pulicarResult()"
                     :loading="loadingF" :disabled="loadingF">{{ $t('analisis.publicar') }}
              </v-btn>

              <v-btn class="c-btn" color="#007AFF" outlined @click="generapdf_ccdes()">{{
                $t('analisis.generar_pdf') }}</v-btn>

              <v-btn class="c-btn ml-5" color="#007AFF" text @click="volver()">
                {{ $t('analisis.salir') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <!-- dialod -->
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ $t('analisis.m_resultados') }}
        </v-card-title>

        <v-card-text>
          {{ $t('analisis.m_resultados_txt1') }}
          {{ $t('analisis.m_resultado_txt2') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="publicarMuestra">
            {{ $t('analisis.aceptar') }}
          </v-btn>

          <v-btn color="green darken-1" text @click="nopublicarMuestra">
            {{ $t('analisis.cancelar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- OVERLAY -->
    <v-overlay opacity=".70" :value="loadingResponse" style="z-index: 999999 !important;">
      <div class="loader">
        <div id="dot1"></div>
        <div id="dot2"></div>
        <div id="dot3"></div>
        <div id="dot4"></div>
        <div id="dot5"></div>
        <div id="dot6"></div>
        <div id="dot7"></div>
        <div id="dot8"></div>
        <div id="dot9"></div>
        <div id="dot10"></div>
        <div id="base1"></div>
        <div id="base2"></div>
        <div id="base3"></div>
        <div id="base4"></div>
        <div id="base5"></div>
        <div class="loader-content">
          <p class="p-body">{{$t('menu.analizando')}}...</p>
        </div>
      </div>
    </v-overlay>
    <detalleOt ref="detalleOt" />
  </div>
</template>
<script>
/* eslint-disable */
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import Treeselect from '@riophae/vue-treeselect';
import Service from '../services/apis';
import i18n from '../plugins/il8n';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// import { delete } from 'vue/types/umd';
import Vue from 'vue';
import Dropdown from 'bp-vuejs-dropdown';
// import { filter } from 'vue/types/umd';
import Swal from 'sweetalert2';
import { resolve } from 'path';
import { reject } from 'q';
export default {
  components: {
    Dropdown,
    Treeselect,
    //toogleNavMenu: () => import('./toogleNavMenu'),
    detalleOt: () => import('./Analisis/DetalleOt'),
    dropdownResultados: () => import('./Analisis/resultadosSoftware.vue'),
  },
  data() {
    return {
      ocultarBtnPublicar:false,
      resultado_getclase: '',
      parametros_list: [],
      cat_riesgo: [
        { id: 0, value: i18n.t('ensayos.bajo_riesgo') },
        { id: 1, value: i18n.t('ensayos.alto_riesgo') }
      ],
      // AAA_steriplex_parametros : {},
      AAA_configuracionSteriplex: {},
      AAA_sampleList: [],
      ubication_simple: '',
      n_ensayo: {},
      datageneral: {},
      datauser: {

        datoenv: 1,
      },
      radios: 3,
      disabledBtnOtSearch:false,
      panels: [
        [0, 1]
      ],
      panel: [0, 1],
      loadot: false,
      dialog: false,
      local: '',
      disabledFile: true,
      muestrasearch: null,
      loadingResponse: false,
      loadingF: false,
      activarAnalisis: true,
      dialogmultiple: false,
      valid: false,
      activeinput: 0,
      valueConsistsOf: 'LEAF_PRIORITY',
      analizar: true,
      accept: [],
      accept_txt: '',
      dataForm: {
        file: null,
        equipoKit: null,
      },
      matrices: [],
      kitMatrices: [],
      check: false,
      current_fecha: '',
      current_time: '',
      publicsample: false,
      errorsample: false,
      completedBitacora: null,
      nadaApublicar: false,
      infoUser: {
        email: '',
        nombre: '',
        direccionCliente: '',
        nombreCliente: '',
        clienteid: 0,
        config: '',
        externo: 0,
      },
      parametroIndefinido: [],
      warningsPublicar:[],
      filesInterpretacion: null,
      muestrasNopublicadas: [],
      snack: {
        snackbar: false,
        txt: null,
        color: 'red',
        error: false,
        icon: '',
        x: null,
        y: 'top',
        colorbtn: '#fff',
      },
      kitEnsayos: [],
      kitPCR: [{
        id: 1,
        name: 'Steriplex Bacterias',
        id_ensayos: [1036, 1037, 1038, 1039], // id ensayos [idali, idsuper, idmnip,idagua,idamb]
      },
        {
          id: 2,
          name: 'Steriplex hongos y levaduras',
          id_ensayos: 998, // id ensayos [idali, idsuper, idmnip,idagua,idamb]
        },
        {
          id: 3,
          name: 'Food Total',
          id_ensayos: [929, 928, 927], // id ensayos [idali, idsuper, idmnip,idagua,idamb]
        },
      ],
      equipoKit: [
        {
          id: 1,
          name: 'ChaiBio',
          pocillos: 16,
        },
        {
          id: 2,
          name: 'Aria',
          pocillos: 96,
        }
      ],
      deterioro: [{
        id: 'DETERIORADO',
        string: 'conDeterioro',
        // name: 'DETERIORADO',
        name: i18n.t('analisis.deteriorado'),
      },
        {
          id: 'NO DETERIORADO',
          string: 'sinDeterioro',
          // name:  'NO DETERIORADO',
          name: i18n.t('analisis.nodeteriorado'),
        },
      ],
      nameCategoriaKitEnsayos: '',
      // showCategoriaKitEnsayos : false,
      categoria_kit_ensayos: [],
      categoria_kit_ensayos_filtered: [],
      matrizKit: [{
        id: 12,
        label: 'Alimentos',
        children: [{
          id: '12_1',
          label: 'Aguas y aguas saborizadas',
        },
          {

            id: '12_2',
            label: 'Jugos pH <= 5',
          },
          {
            id: '12_3',
            label: 'Jugos pH > 5',
          },
          {
            id: '12_4',
            label: 'Bebidas carbonatadas',
          },
        ],
      },
        {
          id: 7,
          label: 'Aguas',
        },
        {
          id: 9,
          label: 'Superficies',
        },
        {
          id: 8,
          label: 'Manipuladores',
        },
      ],
      namekit: null,

      sampleList: [{
        pocillo: 1,
        nomMatriz: null,
        codesample: '',
        matriz_id: null,
        matriz: 'otro',
        kit: null,
        Bac_1: null,
        Bac_2: null,
        Bac_3: null,
        Bac_4: null,
        Bac_5: null,
        prob_1: null,
        prob_2: null,
        prob_3: null,
        prob_4: null,
        prob_5: null,
        checked: false,
        icon: 'mdi-close',
        color: 'red',
        local: '',
        namePocillo: '1',
        deteriorado: null,
        ensayo_name:'',
        grupoEnsayo:'',
        mixId:null,
        mixName:'',
        prefijoKit:'',
        idbitacora:'',
        prefijo_control:'',
        ind_control:'',
      }
      ],
      muestrasAnalizar: [],
      arrayinicial: [1],
      arraycontinua: [1,2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      infoOt: {},
      ensayoSeleccionado: {},
      estatusInicioAnalisis: true,
      overwritePublications: true,
      tipoBitacora:0,
      grupoEnsayo: null,
      isProgram:false,
      programs: [],
      mixGlobal:null,
      kitEjecutar:null,
      activeEdit: false,
      bitAuthuser:null,
      newEquipo: [],
      prefijoProgram:null,
      checkedAllpublish: false,
      isLab:null,
      maxFiles: 0,
      selectedFiles: [],
      equipos: null,
      errors: [],
    };
  },
  computed: {
    ...mapState('Ensayos', ['inicioAnalisis']),
    menudeterioro() {
      const itemDETERIORADO = [
        {
          id: 'DETERIORADO',
          string: 'conDeterioro',
          name: i18n.t('analisis.deteriorado'),
        },
        {
          id: 'NO DETERIORADO',
          string: 'sinDeterioro',
          name: i18n.t('analisis.nodeteriorado'),
        },

      ];
      return itemDETERIORADO;
    },
    canAddFiles() {
      return this.selectedFiles.length < this.maxFiles;
    }
  },
  watch: {
    selectedFiles(newFiles) {
      if (newFiles.length > this.maxFiles) {
        this.selectedFiles = newFiles.slice(0, this.maxFiles);
      }
    }
  },
  beforeDestroy() {
  },
  async created() {
    this.getEnsayosOrProgram();
    this.cambiarStatusAnalisis(false);
    const that = this;
    window.addEventListener('beforeunload', function (e) {
      // se va enviar la alert si se inicio un analissi y no se publico
      if (that.inicioAnalisis) {
        e.preventDefault();
        e.returnValue = i18n.t('analisis.abandonar');
      }
    });
    this.transalte();
    this.transalteKit();

    await this.crearTablaequipo();/// 2022 CREAMOS TABLA DE 16 POSILLOS
    // this.radios = 2;
    const info = {
      datoenv: 1,
    };
    const datosUser = await Service.apiToken('POST', 'get-userinfo', this.$ls.storage.token, info);
    ////console.log({DATOSUSER:datosUser})
    this.infoUser.nombre = `${datosUser.data.user.name} ${datosUser.data.user.apellido}`;
    this.infoUser.email = datosUser.data.user.email;
    this.getParametrosCliente(datosUser.parametros);//ALMACENAMOS LOS PARAMETROS EN EL STORE

    this.infoUser.direccionCliente = datosUser.data.cliente.direccion;
    this.infoUser.nombreCliente = datosUser.data.cliente.nombre;
    this.infoUser.clienteid = datosUser.data.cliente.id;
    this.infoUser.config = datosUser.data.cliente.config_extra;
    this.infoUser.logo = datosUser.data.cliente.img_empresa;
    this.infoUser.id = datosUser.data.user.id;
    this.infoUser.locales = datosUser.data.user.locales;
    this.kitEnsayos = datosUser.ensayosCli;
    this.bitAuthuser =  datosUser.usertxa;
    this.isLab = datosUser.isLab;
    this.getUserInfo(datosUser.usertxa);
    this.categoria_kit_ensayos = datosUser.catalogos.categoria_kit_ensayos;
    this.current_fecha = this.currentDate();
    let configCliente = this.infoUser.config != '' ? JSON.parse(this.infoUser.config) : '';
    if (configCliente != '') {
      if (configCliente.modulos.txa) {
        if (configCliente.modulos.txa.access == '0') {
          this.infoUser.externo = 1;
        }
      }
    }

    // equipos por cliente 2022
    let itemsEquipo = [];
    datosUser.equipo.forEach(element => {
      itemsEquipo.push(element.inlaca_equipo)
    });
    this.equipoKit = itemsEquipo;
    this.newEquipo = itemsEquipo;
    //creamos el formato agrupando los ensayos
    var grouped = _.groupBy(this.kitEnsayos, 'name_ensayo');

    let kit = [];
    Object.keys(grouped).map((i) => {
      let ensayosid = [];
      let id = 0;
      let software = null;
      let comando = null;
      let descripcion = null;
      let grupo = 0;
      let programa_id = 0;

      grouped[i].map((D) => {
        grupo = D.ensayo.grupo_ensayo_id
        id = D.ensayo.id;
        ensayosid.push(D.ensayo.id);
        software = JSON.parse(D.software);
        comando = D.comando;
        descripcion = D.descripcion;
        programa_id = D.tb_r_ensayo_programa.id_programa;
      });
      kit.push({
        id: id,
        name: i,
        id_ensayos: ensayosid,
        software,
        comando,
        descripcion,
        grupo,
        programa_id
      });
    });
    // descomentar en produccion
    this.kitPCR = kit;
    //////console.log({DATASELECTENSAYOS:grouped})
    // obtener configuracion de ensayos de steriplex
    const configuracionSteriplex = await Service.apiToken('POST', 'get-configuracionSeriplex', this.$ls.storage.token);
    // ////console.log({'AAA configuracionSteriplex':configuracionSteriplex});
    this.AAA_configuracionSteriplex = configuracionSteriplex
  },
  methods: {
    ...mapMutations('Ensayos', ['cambiarStatusAnalisis']),
    ...mapMutations('storeAnalisis', ['setvariableSampleList','setenableEditresult','getParametrosCliente','getUserInfo']),
    async transalteKit() {
      const matrizKit = await Service.apiToken('POST', 'list-kit', this.$ls.storage.token, this.datauser);
      // // ////console.log(matrizKit);

      this.kitMatrices = matrizKit.response;

      this.kitMatrices.forEach(e => {
        //    // ////console.log(e.kit);
        e.kit = this.getlang(e.kit);
      });
    },
    async transalte() {
      // // ////console.log('entratransalte');
      const matrizlista = await Service.apiToken('POST', 'list-matriz', this.$ls.storage.token, this.datauser);
      // // ////console.log('matrizlista');
      this.matrices = matrizlista.response;
      // agregamos controles positivos y negativos como matrices
      this.matrices.push({ id:1, matriz: 'Control Positivo' });
      this.matrices.push({ id:0, matriz: 'Control Negativo' });
      this.matrices.push({ id:2, matriz: i18n.t('analisis.cePositivo') });
      this.matrices.push({ id:3, matriz: i18n.t('analisis.ceNegativo') });
      this.matrices.push({ id:4, matriz: i18n.t('analisis.cMedio') });
      this.matrices.push({ id:5, matriz: i18n.t('analisis.cnMedio') });

      this.matrices.forEach(e => {
        e.matriz = this.getlang(e.matriz);
      });

      //({MATRIZCONTROL:this.matrices})
      // // ////console.log({'this.matrices':this.matrices});
    },
    getlang(txt) {
      // // ////console.log('txt', txt);
      if (txt == null) {
        return txt = '--';
      }

      let text_tranform = txt.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "").replace(/,/g, "").replace(/-/g, "");
      // // ////console.log('analisis.'+[`${text_tranform}`]);
      // // ////console.log('traduccion',i18n.t('analisis.superficies'));

      let txt_traduccion = i18n.t('analisis.' + [`${text_tranform}`]);

      // // ////console.log("TRADUCCION",txt_traduccion);
      // // ////console.log("TRADUCCIONincludes",txt_traduccion.includes('analisis.'));
      if (!txt_traduccion.includes('analisis.')) {
        // return i18n.t('analisis.superficies');
        return txt_traduccion;
      } else {
        // return i18n.t('analisis.superficies');
        return txt;
      }
    },
    onCloseOverlay(){
      //console.log('entra a cerrar overlay')
      this.loadingResponse = false;
    },
    getProbHtml(item, pos) {

      ////console.log({ITEMNOOO:item, POS:pos});
      let result = '';
      if (item.resultadofinal) {
        // result = item.resultadofinal;

      } else {


        if (item[`prob_final_${pos}`]) {

          //////console.log('entra a final')
          // result = (item[`prob_final_${pos}`] == 'NA' ) ? '' : `- ${item[`prob_final_${pos}`]} %` ;
          switch (item[`prob_final_${pos}`]) {

            case 'NA':
              result = '';
              break;
            case 'NaN':
              result = '0 %';
              break;

            default:
              result = (item[`Bac_${pos}`] == 'Negative'
                  || item[`Bac_${pos}`] == "Profile error, repeat reaction"
                  || item[`Bac_${pos}`] == 'Error'
                  || item[`Bac_${pos}`] == 'Error_Perfil'
                  || item[`Bac_${pos}`] == 'Gris'
              ) ?  ''  : `- ${item[`prob_final_${pos}`]} %`
              break;
          }
        } else {

          switch (item[`Bac_${pos}`]) {
            case ' ':
              //////console.log({ENTRAACA:item[`Prob_${pos}`]});
              // result = (item[`Prob_${pos}`] == true) ? 'Positivo' : 'Negativo';
              result = (item[`Prob_${pos}`] == true) ? this.namekit : this.namekit;
              result = (item.hasMix == 'si')?item.ensayo_name:i18n.t('analisis.vacio');;
              result = (item.ensayo_name != "")?((item.hasMix == 'si')?item.ensayo_name:i18n.t('analisis.vacio')):(item[`Prob_${pos}`] == true) ? this.namekit : this.namekit;
              break;
            case 'Inhibido':
              result = '';
              break;

            default:
              // if(item[`resultadofinal`]){
              //     result='';
              // }else{
              result = (item[`Prob_${pos}`] == true) ? i18n.t('analisis.positivo') : i18n.t('analisis.negativo');
              // }
              break
          }
        }
      }
      //console.log(result)
      return result;
    },
    async generapdf_ccdes() {
      const samplePubic = [];
      //filtramos y obtenemos info del ensayo analizar

      let infoEnsayo = this.kitPCR.filter(kit => kit.id == this.dataForm.kitPcr);
      //////console.log({SAPLELISTPDF:this.sampleList})
      this.sampleList.forEach((element) => {
        //////console.log({SAMPLESPDF:element});
        samplePubic.push(element);
      });
      const datasend = {
        data: {
          otid: this.dataForm.OT,
          muestreador: (this.infoOt.muestreador) ? this.infoOt.muestreador : null,
          cliente: this.infoUser.nombreCliente,
          logo: this.infoUser.logo,
          clienteId: this.infoUser.clienteid,
          direccion: this.infoUser.direccionCliente,
          ensayo: infoEnsayo[0].name,
          metodologia: infoEnsayo[0].descripcion,
        },
        string: samplePubic,
      }
      const PDFsteriplex = await Service.apiToken('POST', 'pdf-steriplex', this.$ls.storage.token, datasend);

      const url = `${process.env.VUE_APP_API_CC}reporteSteriplex/${PDFsteriplex.data.id}`;
      window.open(url, '_blank');
    },
    generarPDF() {
      const samplePubic = [];
      this.sampleList.forEach((element) => {
        //if (element.codesample !== '' && element.muestra_idres && element.Bac_1 !== 'Inhibicion') {
        samplePubic.push(element);
        //}
      });
      return JSON.stringify(samplePubic);
    },
    async analizarSamples() {
      //console.log({ lista:this.sampleList });
      // recogemos datos
      let pocilloMatriz = [];
      let data_resultados = [];
      let contvalidar = 0;
      this.current_time = this.currentTime();
      let kitid = this.dataForm.kitPcr;
      let kitinfo = this.kitPCR.filter(function (element) {
        return element.id == kitid;
      });
      this.ensayoSeleccionado = this.kitPCR.filter(kit => kit.id == this.dataForm.kitPcr)[0];
      // obtenemos el software a leer y el comando
      let softwareEjecutar = this.kitPCR.filter(kit => kit.id == this.dataForm.kitPcr);

      //console.log({softwareEjecutar:softwareEjecutar});
      let infoequipoSeleccionado = this.equipoKit.filter(equipo => equipo.id == this.dataForm.equipoKit);

      let equipoName = infoequipoSeleccionado[0].equipo.charAt(0).toUpperCase()
          + infoequipoSeleccionado[0].equipo.slice(1).toLowerCase();
      let matrizConact, matrizconcat, frecuencia_matriz;
      let DETERIORADO;
      this.sampleList.forEach(element => {
        matrizConact = 'otro', matrizconcat = '', frecuencia_matriz = 'Otro';

        // ------------------- -------------------
        DETERIORADO = element.deteriorado == 'DETERIORADO' ? 'conDeterioro' : 'sinDeterioro';
        if ((element.kit && !element.deteriorado) || (element.deteriorado && !element.kit)) {
          contvalidar += 1;
        }


        // ------------------- EL BUENO:
        if (element.info_muestra) {
          if (element.info_muestra.categoria_matriz) {
            if (element.info_muestra.categoria_matriz.valor) {
              frecuencia_matriz = element.info_muestra.categoria_matriz.valor;
            }
          }

          if (element.matriz_id) {
            // switch 2
            switch( element.matriz_id ) {
              case 9: case 8: case 7:
                // frecuencia_matriz = 'Superficies';
                frecuencia_matriz = element.matriz;
                break;
              case 7:
                matrizConact = 'agua_sinDeterioro';
                break;
            }
          }

          if (element.info_muestra.sub_categoria_kit) {
            if (frecuencia_matriz == 'PT') {
              // switch 3
              matrizconcat = element.info_muestra.sub_categoria_kit.codigo_steriplex;
              matrizConact = matrizconcat+'_'+DETERIORADO;
            }
          }else{
            frecuencia_matriz = 'Otro';
          }
        } else {
          if (element.matriz_id) {
            // switch 4
            switch( element.matriz_id ) {
              case 9: case 8: case 7:
                // frecuencia_matriz = 'Superficies';
                frecuencia_matriz = element.matriz;
                break;
            }
          }
          // si no existe la muestra obtenemos desde la

          let filtroSubmatriz = this.kitMatrices.filter((matrizlist) => matrizlist.id == element.kit);
          // ////console.log(filtroSubmatriz);
          matrizConact = (filtroSubmatriz.length > 0) ? (filtroSubmatriz[0].valor_esteriplex) ? filtroSubmatriz[0].valor_esteriplex : 'otro' : 'otro';

        }

        ///para clientes que no tienenacceso a slab tomamos
        // if(this.infoUser.externo == 1){
        //     matrizConact = element.matriznombre ? element.matriznombre : 'otro';
        // }
        let grupoEnsayot = element.grupoEnsayo;

        if(element.grupoEnsayo == ""){
          grupoEnsayot = this.grupoEnsayo;
        }

        console.log({ grupoEnsayot });

        const resultdata = {
          cliente: this.infoUser.nombreCliente,
          fecha: this.current_fecha,
          hora: this.current_time,
          kit: element.ensayo_name,
          ot: ((element.info_muestra) ? element.info_muestra.ot_id : '-'),
          id_data_test: '',
          id_muestra: ((element.info_muestra) ? element.info_muestra.id : '-'),
          planta: ((element.local) ? element.local.nombre_local : '-'),
          zona: ((element.info_muestra) ? element.info_muestra.linea : '-'),
          pocillo : element.pocillo,
          prefijo : (element.prefijo) ? element.prefijo : null,
          categoria_kit_ensayo_id : (element.categoria_kit_ensayo_id) ? element.categoria_kit_ensayo_id : null,
          grupo_ensayo_id:grupoEnsayot,
          equipo: equipoName ,
          coordenada:((element.namePocillo) ? element.namePocillo : null),
          programa:((element.idPrograma) ? element.idPrograma : null),
          prefijoKit: this.radios === 4 ? 'F41' : this.prefijoProgram,
          idbitacora: element.idbitacora,
          mix:(element.mixName)?element.mixName:this.mixGlobal,//CAMBIAR POR NOMBRE
          medio:element.name,//CAMBIAR POR NOMBRE
          prefijo_control: !isNaN(element.codesample)?'Mu':element.codesample,
          int_control : element.ind_control,
        }
        data_resultados.push(resultdata)

        const pocillodata = { // item.kit
          pocillo: element.pocillo,
          //matriz: element.matriz, //matrizConact
          matriz: 'otro', //matrizConact
          M_freq:'Otro', //frecuencia_matriz,
          coord:element.namePocillo
        }
        pocilloMatriz.push(pocillodata)
      });

      if (this.selectedFiles === '' || this.selectedFiles === undefined || this.selectedFiles === null) {
        Swal.fire({
          title: "Error",
          text: i18n.t('analisis.must_select') + ' ' + this.maxFiles + ' ' + i18n.t('analisis.files'),
          icon: 'warning',
          confirmButtonText: "Ok",
        });
        return;
      }
      if (this.selectedFiles.length !== this.maxFiles){
        Swal.fire({
          title: "Error",
          text: i18n.t('analisis.must_select') + ' ' + this.maxFiles + ' ' + i18n.t('analisis.files'),
          icon: 'warning',
          confirmButtonText: "Ok",
        });
        console.log("faltan archivos22");
        return;
      }
      this.loadingResponse = true;
      const data = new FormData();
      ///funciones 2022
      ///fin funciones nuevas
      this.infoEnsayo = softwareEjecutar;
      const archivo = this.dataForm.file;
      // const datosV = await Service.apiToken('POST', 'get-userinfo', this.$ls.storage.token);
      console.log({data_resultados});
      data.append('cliente_id', this.infoUser.clienteid); ///////id cliente
      data.append('data_result', JSON.stringify(data_resultados)); ///
      this.selectedFiles.forEach((file) => {
        data.append('file', file, file.name); ///archivo ajunto
      });
      data.append('kit_pcr', this.dataForm.kitPcr); /// ensayo seleccionado
      data.append('equipo_pcr', this.dataForm.equipoKit); // idequiposeleccionado
      data.append('cliente_externo', this.infoUser.externo); //si usa o no txalab
      data.append('matrizkit', JSON.stringify(pocilloMatriz)); //json de pocillos y kit
      data.append('infoEnsayo', JSON.stringify(softwareEjecutar)); //json de pocillos y kit
      data.append('infoUser',  JSON.stringify(this.infoUser));
      data.append('programa',  this.isProgram);

      // ---------------------
      // VERSION DEL SOFTWARE:
      //////console.log({'AAAAA1' : softwareEjecutar})
      const withSamples = this.radios !== 4;
      const kit = softwareEjecutar[0].software.kit;
      let version = softwareEjecutar[0].software.ubication_simple;
      if(withSamples){
        version = version.replace("scripts/R/", "");
        version = version.split("/")[0];
        version = version.split("_");
        version = version[version.length - 1];
        this.ubication_simple = kit + ' ' + version
      }
      this.kitEjecutar = softwareEjecutar[0];
      // ---------------------
      ////console.log(data);
      data.append('equipoSeleccionado', JSON.stringify(infoequipoSeleccionado));//enviamos info del equipo seleccionado
      data.append('tipoAnalisis', this.radios);
      // ////console.log({sampleList:this.sampleList,data:data});

      // const dataRes = await Service.api('POST', 'multiplex', data); // version 1 funcionando estatico
      // const dataRes = await Service.api('POST', 'multiplexAnalisis', data);
      //console.log({multiplex: Object.fromEntries(data)});
      const dataRes = await Service.apiTokenMultipart('POST', 'multiplexAnalisis', this.$ls.storage.token, data);
      // infoEnsayo
      this.analizar = false;
      //console.log({ dataRes });
      if (dataRes.data.status === 'error') {
        this.snack.snackbar = true;
        this.snack.color = 'red';
        this.snack.colorbtn = '#fff',
          this.snack.txt = i18n.t('analisis.sube');
        this.loadingResponse = false;
        return;
      }

      if(dataRes.data.data.length == 0){
        this.alertForSelectData(i18n.t('analisis.noResults'));
        this.loadingResponse = false;
        this.analizar = true;
        this.volverSeguro();
        return;
      }

      if (this.dataForm.equipoKit == 2) {
        //await this.activaItemspocillosAbecedario();
      }
      const dataAnalize = dataRes.data.data;
      this.filesInterpretacion = {
        files_results: dataRes.data.files_results,
        files_interpretar: dataRes.data.files_interpretar,
      };
      ////console.log({DATAANALIZE:dataAnalize});
      // activamos bandera que se analizo
      this.cambiarStatusAnalisis(true)
      /* eslint-disable no-param-reassign */
      dataAnalize.forEach((val) => {
        this.panels.push([0, 1]);

        this.sampleList.map((vval) => {
          /* eslint-disable eqeqeq */

          //   vval.namePocillo = val.Pocillo;
          if(softwareEjecutar[0].software.kit == 'food' && val.namePocillo){
            val.Pocillo =  val.namePocillo;
          }

          if (vval.namePocillo == val.Pocillo) {
            // si no tiene clase le metemos clase
            if(!val.clase){
              val.clase = val.Resultado
            }
            const dataAux = this.getBact(val);
            // console.log({dataAux:dataAux});
            for (let index = 1; index < dataAux.length + 1; index += 1) {
              vval.clase = dataAux[index - 1].clase;
              if (dataAux[index - 1].nombre.includes('_')) {
                var aux = dataAux[index - 1].nombre.split('_');
                vval[`Bac_${index}`] = aux[0] + ' ' + aux[1];
              } else {
                vval[`Bac_${index}`] = dataAux[index - 1].nombre;
              }
              vval[`Prob_${index}`] = dataAux[index - 1].value;
              vval.outlier = val.Muestra_outlier;
            }
            // zigoplex agregamos un valor mas al objeto principal
            vval.resultadofinal = val.Resultado;

            vval.hasMix = val.hasMix;
          }
          return true;
        });
        return true;
      });

      this.n_ensayo = softwareEjecutar[0].name;

      this.sampleList.sort(function(a, b) {
        var numeroA = parseInt(a.namePocillo.slice(1));
        var numeroB = parseInt(b.namePocillo.slice(1));

        var letraA = a.namePocillo.slice(0, 1);
        var letraB = b.namePocillo.slice(0, 1);

        if (numeroA == numeroB) {
          return letraA.localeCompare(letraB);
        }
        return numeroA - numeroB;
      });

      if (softwareEjecutar[0].software.kit == 'food') {
        this.analizarSamplesfood();
      }
      else { //////////////////////////// si es HyL o Bacterias
        this.analizarSamples2();
      }

      this.AAA_sampleList = this.sampleList
      this.sampleList.map(item2 => { item2.coincidencias = [] })

      // guardamos la variable sampleList en el store para actualizar desde otro componente
      this.setvariableSampleList(this.sampleList);
      console.warn('termino de analizar funcion principal');

      setTimeout(() => {
        this.loadingResponse = false;
      }, "3000")
      console.log({SAMPLELIST:this.sampleList});
    },
    analizarSamplesfood() {
      ////console.log('Food analisis');
      this.arraycontinua = [1,2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
      let kitid = this.dataForm.kitPcr;
      let kitinfo = this.kitPCR.filter(function (element) {
        return element.id == kitid;
      });
      ////console.log({ kitid: kitid, kitinfo: kitinfo });
      this.namekit = kitinfo[0].name;
      this.sampleList.forEach((element) => {

        element.foodtotal = true;
        // comprobamos del patogeno 2 a la 5 di todos son falsos
        let contpatogenosPos = 0;
        for (let index = 2; index < 6; index++) {
          if (element[`Prob_${index}`] == true) {
            contpatogenosPos++;;
          }
        }

       // console.log({ element: element });
        // si la probabilidad 1 es diferente de true es inhibido en clase
        if ((element.Prob_1 == 'INHIBIDA' || element.Prob_1 == 'PCR INHIBITION') && !element.control && contpatogenosPos == 0) {
          element.clase = 'inhibido';
          // element.Bac_1 = 'Inhibicion';
          element.Bac_1 = 'Inhibido';
          // convertimos sus Bac_1...n en NA para no mostrar
          for (let index = 2; index < 6; index++) {
            element[`Bac_${index}`] = 'NA';
          }
        } else {
          element.Bac_1 = contpatogenosPos == 0 ? ' ' : ' ';
          element.Prob_1 = (contpatogenosPos == 0) ? false : true;
        }

        // recorremos los patogenos que nos devuelve perl
        // y lo buscamos en patogenos del ensayo de la muestra
        for (let index = 2; index < 6; index++) {
          if (element.parametros) {

            let bacteria = element[`Bac_${index}`];
            let infoparamtros = [];
            /*if (Array.isArray(element.info_ensayo_muestra)) {
                            infoparamtros = element.info_ensayo_muestra[0].ensayo.patogeno_new_has_ensayos;
                        } else {
                            infoparamtros = element.info_ensayo_muestra.ensayo.patogeno_new_has_ensayos;
                        }*/
            //console.log({param:element.parametros});
            infoparamtros = (element.parametros) ? JSON.parse(element.parametros) : [];
            //console.log({ infoparamtros: infoparamtros, bacteria: bacteria });
            // let infoparamtros = element.info_ensayo_muestra.ensayo.patogeno_new_has_ensayos;
            let encontrado = 0;
            infoparamtros.forEach(paramensa => {
              let nameparamEnsa = paramensa.ncorto;
              if (nameparamEnsa === bacteria) {
                encontrado++;
              }
            });
            if (encontrado === 0) {
              delete element[`Bac_${index}`];
              delete element[`Prob_${index}`];
              delete element[`prob_${index}`];
            }

          }
          else {
            /*
                        if(this.dataForm.kitPcr === 3){
                            // for (let index = 2; index < 6; index++) {

                                let bacteria = element[`Bac_${index}`]
                                let bac1 =  'Sal';
                                let bac2 =  'Lmon';
                                let encontrado = 0;

                                if(bacteria === bac1 || bacteria === bac2){
                                    encontrado++;
                                }
                                if(encontrado > 0){
                                    delete element[`Bac_${index}`];
                                    delete element[`Prob_${index}`];
                                    delete element[`prob_${index}`];
                                }



                            // }
                        }*/
            //nuevo
            let kitid = this.dataForm.kitPcr
            let filtro = this.kitPCR.filter(function (element) {
              return element.id == kitid;
            });
            // ////console.log({filtro});

            if (filtro[0].parametros) {
              ////console.log('entra a patogenos');
              let bacteria = element[`Bac_${index}`];
              ////console.log({ bacteria });
              ////console.log(filtro[0].parametros);

              let encontrado = 0;
              filtro[0].parametros.forEach(paramensa => {
                let nameparamEnsa = paramensa;
                if (nameparamEnsa === bacteria) {
                  encontrado++;
                }
              });
              if (encontrado === 0) {
                delete element[`Bac_${index}`];
                delete element[`Prob_${index}`];
                delete element[`prob_${index}`];
              }

            }
          }

        }

        let keysMuestras = Object.keys(element);
        let getNumberParams = 0;
        let getNumber_Bac_ = 0;
        let getNumber_prob_ = 0;
        let getNumber_Prob_ = 0;
        for (const keysMuestra of keysMuestras) { if (keysMuestra.indexOf('Bac_') !== -1) { getNumberParams++; } }
        console.log({getNumberParams});
        let originalElement = structuredClone(element);
        let pruebaAR = element; //structuredClone(element);
        for (let keyElement_idx = 0; keyElement_idx < keysMuestras.length; keyElement_idx++) {
          let keyElement = keysMuestras[keyElement_idx];
          let bac_ = keyElement.indexOf('Bac_') !== -1;
          let prob_ = keyElement.indexOf('prob_') !== -1;
          let Prob_ = keyElement.indexOf('Prob_') !== -1;
          //console.log({keyElement,bac_})
          let indexSearch = null;
          let number_keyElement = null;
          if(bac_){
            getNumber_Bac_++;
            number_keyElement = keyElement.split(/[_]+/).pop()
            indexSearch = number_keyElement;
            //console.log({keyElement,news: 'Bac_'+getNumber_Bac_});
            if(keyElement !== 'Bac_'+getNumber_Bac_){
              pruebaAR['Bac_'+getNumber_Bac_] = pruebaAR[keyElement];
              delete pruebaAR[keyElement];
            }
          }
          if(prob_){
            getNumber_prob_++;
            number_keyElement = keyElement.split(/[_]+/).pop()
            indexSearch = number_keyElement;
            //console.log({keyElement,news: 'Bac_'+getNumber_Bac_});
            if(keyElement !== 'prob_'+getNumber_prob_){
              pruebaAR['prob_'+getNumber_prob_] = pruebaAR[keyElement];
              delete pruebaAR[keyElement];
            }
          }
          if(Prob_){
            getNumber_Prob_++;
            number_keyElement = keyElement.split(/[_]+/).pop()
            indexSearch = number_keyElement;
            //console.log({keyElement,news: 'Bac_'+getNumber_Bac_});
            if(keyElement !== 'Prob_'+getNumber_Prob_){
              pruebaAR['Prob_'+getNumber_Prob_] = pruebaAR[keyElement];
              delete pruebaAR[keyElement];
            }
          }
        }
        console.log('element_analizarSamplesfood');
        //element = pruebaAR;
        console.log({originalElement,element});
      });

      console.warn('termino de analizar samples food');
      this.analizar = false;
      //this.loadingResponse = false;
    },
    analizarSamples2() {
      // verificamos si es bacterias o dehongo y levarudas 1= bcteria y 2 hongos
      //   let matrizPeso = this.matriz_peso;
      let matrizPeso = this.$store.state.Ensayos.matrizhongos_peso;
      if (this.dataForm.kitPcr === 2) {
        matrizPeso = this.$store.state.Ensayos.matrizhongos_peso;
      } else if (this.dataForm.kitPcr === 1) {
        matrizPeso = this.$store.state.Ensayos.matriz_peso;
      }
      matrizPeso = JSON.parse(JSON.stringify(matrizPeso));
      this.sampleList.forEach((element) => {
        ///validamos si es pool se cambia en Bac_,Prob_,prob_,prob_final_
        let isPool = this.checkIFIsPool(element);
        if (isPool) {

          let vueltas = 24;
          for (let index = 2; index <= vueltas; index++) {
            /* eslint-disable no-param-reassign */
            if (element[`Bac_${index}`]) {
              delete element[`Bac_${index}`];
              delete element[`prob_${index}`];
              delete element[`Prob_${index}`];
              delete element[`prob_final_${index}`];
            }

          }
          // publicar en la pocicion 1 que es positivo solo si es una clase (1...24)
          element[`Bac_1`] = 'Positivo';
          element[`Prob_1`] = 0;
          // element[`clase`] = 1;
          // element[`outlier`] = 'NO';
          // item.outlier == 'NO'
          element[`prob_final_1`] = 0;
        }
        let nombreMatriz = '--';
        if (element.matrizId) {
          if (typeof (element.matrizId) === 'string') {
            const matrizAlim = this.matrizKit[0].children;
            const filtermat = matrizAlim.filter(
              (matrizlist) => matrizlist.id === element.matrizId,
            );
            nombreMatriz = filtermat[0].label;
          } else {
            const filtermat = this.matrizKit.filter(
              (matrizlist) => matrizlist.id === element.matrizId,
            );
            nombreMatriz = filtermat[0].label;
          }
        }
        /* eslint-disable no-param-reassign */
        element.nomMatriz = nombreMatriz;
        /* eslint-disable no-param-reassign */
        for (let index = 1; index < 16; index += 1) {
          if (element[`Prob_${index}`] && element[`Prob_${index}`] > 0 && !false) {
            let prob = element[`Prob_${index}`];
            let result = [];

            switch (element.matrizId) {
              case '12_1':
              default:
                result = matrizPeso.filter((word) => word.Bacteria === element[`Bac_${index}`]);

                prob = element[`Prob_${index}`];
                break;
              case '12_2':
                result = matrizPeso.filter((word) => word.Bacteria === element[`Bac_${index}`]);
                if (result.length > 0 && element[`Prob_${index}`] > 20) {
                  prob = element[`Prob_${index}`] * result[0].Jugos1;
                  prob = element[`Prob_${index}`] * 4;
                }
                break;
              case '12_3':
                result = matrizPeso.filter((word) => word.Bacteria === element[`Bac_${index}`]);
                if (result.length > 0 && element[`Prob_${index}`] > 20) {
                  prob = element[`Prob_${index}`] * result[0].Jugos2;
                  prob = element[`Prob_${index}`] * 4;
                }
                break;
              case '12_4':
                result = matrizPeso.filter((word) => word.Bacteria === element[`Bac_${index}`]);
                if (result.length > 0 && element[`Prob_${index}`] > 20) {
                  prob = element[`Prob_${index}`] * result[0].Bebidas;
                  prob = element[`Prob_${index}`] * 4;
                }
                break;
            }
            /* eslint-disable no-param-reassign */
            if (isNaN(prob)) { //cuando no es numero
              element[`prob_final_${index}`] = element[`Prob_${index}`];
            }
            else {
              element[`prob_final_${index}`] = (prob > 100) ? 100 : prob;
            }


          }
          if (element[`Prob_${index}`] && isNaN(element[`Prob_${index}`])) {
            element[`prob_final_${index}`] = element[`Prob_${index}`];
          }
          else {
            if (element[`Prob_${index}`] && element[`Prob_${index}`] <= 0) {
              element[`prob_final_${index}`] = 0;
            }
          }

        }
        let dataAux = this.getBact(element, '1');

        if (element.outlier == 'SI' && !isPool) {
          element.Bac_1 = 'Microorganismo no registrado';
          element.Prob_1 = '100';
          element.prob_final_1 = '100';
          element.clase = i18n.t('analisis.negativo');
          for (let index = 2; index < dataAux.length + 1; index += 1) {
            /* eslint-disable no-param-reassign */
            element[`Bac_${index}`] = 'NA';
            element[`Prob_${index}`] = 'NA';
          }
        } else {

          if (dataAux.length > 0) {
            const firstData = dataAux[0];
            const data = [];

            dataAux.map((v) => {
              if (v.nombre !== firstData.nombre) {
                data.push(v);
              }
              return true;
            });
            dataAux = _.orderBy(data, ['value'], ['desc']);

            // PRIMER ELEMENTO DEL ARREGLO ( NUMERO MAS ALTO Y CALOR FINAL )
            /* eslint-disable no-param-reassign */
            element.Bac_1 = firstData.nombre;
            element.Prob_1 = (firstData.value_final > 100) ? 100 : firstData.value_final;
            element.prob_final_1 = (firstData.value_final > 100) ? 100 : firstData.value_final;

            // ARREGLOS ACOMODADOS POR VALOR
            let ciclos = dataAux.length + 1;
            for (let index = 2; index <= ciclos; index += 1) {
              /* eslint-disable no-param-reassign */
              element[`Bac_${index}`] = dataAux[index - 2].nombre;
              element[`Prob_${index}`] = dataAux[index - 2].value;
              element[`prob_final_${index}`] = (dataAux[index - 2].value_final > 100) ? 100 : dataAux[index - 2].value_final; // agrega por que seguia conservado el valor antes del proceso
            }
          }
        }
      });


      // aqui hacemos la multiplicacion de cada bicho
      this.loadingResponse = false;
      //this.analizar = false;
      // ////console.log('samplelist',this.sampleList);
    },
    charZanitizeToTraductios(str){
      return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    },
    getBact(item, type = '0') {
      let arrAux = [];
      item.clase = (item.Clase) ? item.Clase : item.clase;
      if (item.clase && (item.clase == 'Negativo' || item.clase == 'Vacio' || item.clase == 'Vacío' || item.clase.toLowerCase() == 'inhibido')) {

        // item.clase = (item.clase.toLowerCase() == 'inhibido') ? 'Negativo' : item.clase; // para que la clase inhivido se publique como negativo
        let classBact = (item.clase === 'Vacio' || item.clase === 'Vacío') ? 'empty_reprocess' :  item.clase;
        console.log({item,clas:item.clase,vac: (item.clase === 'Vacio' || item.clase === 'Vacío'),classBact})
        arrAux.push({
          nombre: item.clase,//i18n.t('analisis.'+this.charZanitizeToTraductios(item.clase)),
          value: 100,
          value_final: 100,
          clase: classBact
        });

      }
      else if (item.clase && (item.clase == 'Error_Perfil' || item.clase == 'Error' || item.clase == 'Gris')) {
        switch (item.clase) {
          case 'Error_Perfil': case 'Error':
            arrAux.push({
              //nombre: i18n.t('analisis.repetirReaccion'),
              nombre: 'Error',
              value: 100,
              value_final: 100,
              clase: (item.Clase) ? item.Clase : item.clase
            });
            break;
          case 'Gris':
            arrAux.push({
              //nombre: i18n.t('analisis.reaccionGris'),
              nombre: item.clase,
              value: 100,
              value_final: 100,
              clase: (item.Clase) ? item.Clase : item.clase
            });
            break;
        }

      }
      else {
        for (var i = 1; i < 16; i++) {

          if (item['Bac_' + i] && item['Bac_' + i] != 'NA') {
            // if (this.dataForm.kitPcr == 3 || this.dataForm.kitPcr == 4){
            if (this.infoEnsayo[0].software.kit == 'food') {
              arrAux.push({
                nombre: item['Bac_' + i],
                value: (item[`status_${i}`]) ? item[`status_${i}`] : false,
                value_final: (item[`status_${i}`]) ? item[`status_${i}`] : false,
                clase: null,//(item.Clase) ? item.Clase : item.clase, // no tiene clase en food total
                value_order: (item[`status_${i}`]) ? 1 : 0,
              })
            }
            else {
              arrAux.push({
                nombre: item['Bac_' + i],
                value: (isNaN(item[`Prob_${i}`])) ? item[`Prob_${i}`] : parseFloat(item[`Prob_${i}`]),
                value_final: (item[`prob_final_${i}`] && isNaN(item[`prob_final_${i}`])) ? item[`Prob_${i}`] : (item[`prob_final_${i}`]) ? parseFloat(item[`prob_final_${i}`]) : 0,
                clase: (item.Clase) ? item.Clase : item.clase,
                value_order: (item[`prob_final_${i}`] && isNaN(item[`prob_final_${i}`])) ? 0 : (item[`prob_final_${i}`]) ? parseFloat(item[`prob_final_${i}`]) : 0,
              })
            }
          }
        }
      }

      if (type === '0') {

        arrAux = _.orderBy(arrAux, ['value'], ['desc']);
      } else if (type === '1') {
        // arrAux = _.orderBy(arrAux, ['value_final'], ['desc']);

        arrAux = _.orderBy(arrAux, ['value_order'], ['desc']);
      }
      console.warn({getBact:arrAux});
      return arrAux;
    },
    async pulicarResult() {
      //console.log({USERDATA:this.bitAuthuser})
      if(this.bitAuthuser.bitauth_userdatum.rol_id == 2){
        this.ocultarBtnPublicar = true;
      }
      console.log({ publicar: this.sampleList });
      const muestraPublicar = [];
      const muestraNopublicar = [];
      this.sampleList.forEach((element) => {

        // eliminamos una llave
        if (element.info_muestra) {
          if (element.info_muestra.hasOwnProperty("ensayo_muestras")) {
            delete element.info_muestra.ensayo_muestras;
          }
          if (element.hasOwnProperty("info_ensayo_muestra")) {
            delete element.info_ensayo_muestra.ensayo;
          }
        }

        if (element.clase == 'Error_Perfil' || element.clase == 'Gris') {
          if (element.codesample !== '') {
            muestraNopublicar.push(element);
          }
        } else {
          if (element.codesample !== '' && element.muestra_idres) {
            muestraPublicar.push(element);
          }
          // if (element.codesample !== '' && element.muestra_idres && element.Bac_1 === 'Inhibicion') {
          //     muestraNopublicar.push(element);
          // }
        }
      });

      //NADA QUE PUBLICAR
      if (muestraPublicar.length == 0) {
        this.nadaApublicar = true;
        return;
      }
      this.nadaApublicar = false;
      this.muestrasNopublicadas = muestraNopublicar;

      // console.log('locales', JSON.parse(this.infoUser.locales));

      const muestrasEnv = {
        muestraslist: muestraPublicar,
        radios: this.radios,
        ot: this.dataForm.OT,
        overwritePublications: this.overwritePublications,
        user_id:this.infoUser.id,
        rol_id:this.bitAuthuser.bitauth_userdatum.rol_id,
        cliente_id : this.infoUser.clienteid,
        locales: null,
        isLab:this.isLab,
        filesInterpretacion: this.filesInterpretacion
      };
      // console.log({DATAPUBLIOCATED:muestrasEnv});

      this.loadingF = true;
      /*if(this.bitAuthuser.bitauth_userdatum.rol_id == 3 && this.isLab || this.bitAuthuser.bitauth_userdatum.rol_id == 6 && this.isLab){
        new Promise(async (resolve, reject) => {
          await this.swalPassConfirm(resolve, reject );

        }).then(async (response) => {
          ////console.log({LARESPUESTADELAPROMESA:response})

          const publicarMuestra = await Service.apiToken('POST', 'publicar-muestras2', this.$ls.storage.token, muestrasEnv);
          //{"data":{"warnings_errores_publish":[],"fecha_hora":"2023-08-25 19:38:00","status":"ok","message":"successfully_request"}}

          this.publicsample = false;
          this.errorsample = false;
          if(publicarMuestra.data.status === 'ok'){
            this.publicsample = true;
            this.completedBitacora = publicarMuestra.data.completedBitacora;
          }else{
            this.errorsample = true;
          }
          //this.parametroIndefinido = publicarMuestra.indefinidos;
          this.warningsPublicar = publicarMuestra.data.warnings_errores_publish;
          this.loadingF = false;
          this.cambiarStatusAnalisis(false);
          this.gettop();
        })

      }
      else{*/


        const publicarMuestra = await Service.apiToken('POST', 'publicar-muestras2', this.$ls.storage.token, muestrasEnv);

        console.log("Publicar!!");

        console.log({publicarMuestra});
        if (publicarMuestra.errors_semi && publicarMuestra.errors_semi.length > 0) {
          this.errors = publicarMuestra.errors_semi;
          this.errorsample = true;
        }
        this.publicsample = false;
        this.errorsample = false;
        if(publicarMuestra.data.status === 'ok'){
          this.publicsample = true;
          this.completedBitacora = publicarMuestra.data.completedBitacora;
        }else{
          this.errorsample = true;
        }

        //this.parametroIndefinido = publicarMuestra.indefinidos;
        this.warningsPublicar = publicarMuestra.data.warnings_errores_publish;
        this.loadingF = false;
        this.cambiarStatusAnalisis(false);
        this.gettop();
      /*}*/

    },
    async swalPassConfirm(resolve, reject){

      Swal.fire({
        title: i18n.t('analisis.enter_pass'),
        input: 'text',
        inputAttributes: {

          autocomplete:"nope"
        },
        showCancelButton: true,
        confirmButtonText:i18n.t('analisis.btn_confirm'),
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          let userData = {
            email:this.bitAuthuser.username,
            password:login
          }
          return fetch(`${process.env.VUE_APP_URL_API}login`, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(response => {
              ////console.log({LARESPUESTA:response})
              if (!response.ok) {
                throw new Error(response.statusText)
              }
              return response.json()
            })
            .catch(error => {
              Swal.showValidationMessage(
                `La contraseña es incorrecta`
              )
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        ////console.log({ELLRESULTADO:result})
        if (result.isConfirmed) {
          resolve('ok');
          this.loadingF = false;
        }else{
          reject();
          this.loadingF = false;
        }
      })
    },
    selectMatriz(e) {
      this.sampleList.forEach((element) => {
        if (element.checked === true) {
          element.matrizId = e.id;
        }
      });
    },
    checkAll() {
      if (this.check === true) {
        this.sampleList.forEach((element) => {
          element.checked = true;
        });
      } else {
        this.sampleList.forEach((element) => {
          element.checked = false;
        });
      }
    },
    async buscarMuestra(item, index) {
      if (item.codesample === '') {
        this.sampleList.forEach((element) => {
          if (element.pocillo === item.pocillo) {
            element.icon = 'mdi-close';
            element.color = 'red';
            element.codesample = '';
            element.muestra_idres = '';
            element.matriz_id = null;
            element.matriznombre = '';
            element.deteriorado = '';
            element.kit = 0;
            element.checked = false;
          }
        });
        return;
      }
      // buscamos is ya esta en algun item mas
      let contsample = 0;
      this.sampleList.forEach((element) => {
        // eslint-disable-next-line
        if (element.codesample == item.codesample) {
          contsample += 1;
        }
      });
      if (contsample > 1) {
        this.sampleList[index].codesample = '';
        return;
      }
      // termina busqueda si esta repetido
      const idensayos = this.kitPCR.filter((word) => word.id === this.dataForm.kitPcr);
      const datasend = {
        sample_code: item.codesample,
        ensayoId: idensayos[0].id_ensayos,
      };
      if (this.infoUser.externo == 0) {
        const muestra = await Service.apiToken('POST', 'search-sample',
          this.$ls.storage.token, datasend);
        if (muestra.response === null) {
          this.snack.snackbar = true;
          this.snack.color = 'red';
          this.snack.colorbtn = '#fff',
            this.snack.txt = i18n.t('analisis.mn');
        } else {
          this.muestrasearch = muestra;
          if (muestra.response.publicado === 1) {
            new Promise(async (resolve, reject) => {
              await this.swalConfirmation(false, this.$t('analisis.are_you_sure_overwrite_sample'), this.$t('analisis.yes_overwrite'), this.$t('analisis.no_keep_current'), resolve, reject,'ok','' );

            }).then(async (response) => {
              //PROMISE RESOLVE
              this.overwritePublications = true;
              this.snack.snackbar = true;
              this.snack.color = '#F5F5F5';
              this.snack.colorbtn = '#007AFF',
                this.snack.txt = i18n.t('analisis.m');
              ////console.log({muestra:muestra});

              this.sampleList.forEach((element) => {
                if (element.codesample == item.codesample) {
                  element.icon = 'mdi-check-outline';
                  element.color = 'gren';
                  element.muestra_idres = muestra.response.id;
                  element.matriz_id = muestra.muestra.id_matriz;
                  //element.matriz_id = muestra.muestra.control == null ? muestra.muestra.id_matriz : muestra.muestra.control;
                  // element.matriznombre = muestra.kitmatriz !== null  ? muestra.kitmatriz.kit : ''
                  element.matriznombre = muestra.muestra.control == null ? muestra.kitmatriz !== null ? muestra.kitmatriz.kit : '' : muestra.muestra.control == '1' ? 'Control Positivo' : 'Control Negativo';
                  // element.deteriorado = muestra.muestra.deteriorante;
                  element.deteriorado = muestra.muestra.control == null ? muestra.muestra.deteriorante : '';
                  element.matriz = muestra.muestra.matriz;
                  // element.kit = muestra.muestra.id_kit;
                  element.kit = muestra.muestra.control == null ? muestra.muestra.id_kit : 0;
                  element.info_muestra = muestra.muestra;
                  element.info_ensayo_muestra = muestra.ensayo_info;
                  element.checked = false;
                  element.local = ((muestra != null) ? muestra.muestra.ot_rel.local : '');
                  element.ensayo_name = muestra.ensayo_info[0].ensayo.ensa_informe;
                  element.mixName = this.mixGlobal;
                  element.categoria_kit_ensayo_id = (muestra.medio != null)?this.getMedio(muestra.medio)[0]:null;
                  element.nombre = (muestra.medio != null)?this.getMedio(muestra.medio)[1]:null;
                }
              });

            }).catch(async (error) => {
              //PROMISE REJECT
              this.overwritePublications = false;

            });

          }else{
            this.overwritePublications = true;
            this.snack.snackbar = true;
            this.snack.color = '#F5F5F5';
            this.snack.colorbtn = '#007AFF',
              this.snack.txt = i18n.t('analisis.m');
            ////console.log({muestra:muestra});

            this.sampleList.forEach((element) => {
              if (element.codesample == item.codesample) {
                element.icon = 'mdi-check-outline';
                element.color = 'gren';
                element.muestra_idres = muestra.response.id;
                element.matriz_id = muestra.muestra.id_matriz;
                //element.matriz_id = muestra.muestra.control == null ? muestra.muestra.id_matriz : muestra.muestra.control;
                // element.matriznombre = muestra.kitmatriz !== null  ? muestra.kitmatriz.kit : ''
                element.matriznombre = muestra.muestra.control == null ? muestra.kitmatriz !== null ? muestra.kitmatriz.kit : '' : muestra.muestra.control == '1' ? 'Control Positivo' : 'Control Negativo';
                // element.deteriorado = muestra.muestra.deteriorante;
                element.deteriorado = muestra.muestra.control == null ? muestra.muestra.deteriorante : '';
                element.matriz = muestra.muestra.matriz;
                // element.kit = muestra.muestra.id_kit;
                element.kit = muestra.muestra.control == null ? muestra.muestra.id_kit : 0;
                element.info_muestra = muestra.muestra;
                element.info_ensayo_muestra = muestra.ensayo_info;
                element.checked = false;
                element.local = ((muestra != null) ? muestra.muestra.ot_rel.local : '');
                element.ensayo_name = muestra.ensayo_info[0].ensayo.ensa_informe;
                element.mixName = this.mixGlobal;
                element.categoria_kit_ensayo_id = (muestra.medio != null)?this.getMedio(muestra.medio)[0]:null;
                element.nombre = (muestra.medio != null)?this.getMedio(muestra.medio)[1]:null;
              }
            });
          }


        }
      }
    },
    checkFile() {
      if (this.dataForm.file != null) {
        if (this.dataForm.file.name.match(this.accept) == null) {

          this.dataForm.file = null;
          this.snack.snackbar = true;
          this.snack.color = 'red';
          this.snack.colorbtn = '#fff',
            this.snack.txt = i18n.t('analisis.formato_invalido') + ' ' + this.accept_txt + '.';

        }
      }
      if (this.dataForm.kitPcr) {
        this.activeinput = 1;
      } else {
        this.activeinput = 0;
      }

      if(this.radios == 3){
        this.activeinput = 0;
      }

      if (this.dataForm.kitPcr && this.dataForm.file) {
        ////console.log({RADIO:this.radios});
        this.activarAnalisis = false;
      } else if(this.radios == 3) {

        //this.activarAnalisis = false;
      }else{
        this.activarAnalisis = true;
      }
    },
    async activarMuestras(item) {
      //////console.log(item)
      //activa input
      this.getMix(item);
      ////console.log({ENSAYOIDD:this.dataForm.kitPcr});
      if (this.dataForm.kitPcr) {
        this.activeinput = 1;
      } else {
        this.activeinput = 0;
      }

      if (this.dataForm.kitPcr && this.dataForm.file) {
        this.activarAnalisis = false;
      }else if(this.radios == 3) {
        this.activarAnalisis = false;
      } else  {
        this.activarAnalisis = true;
      }

      let softwareEjecutar = this.kitPCR.filter(kit => kit.id == this.dataForm.kitPcr);
      if (softwareEjecutar.length > 0) {
        softwareEjecutar = softwareEjecutar[0];

        this.categoria_kit_ensayos_filtered = this.categoria_kit_ensayos.filter(item => item.id_grupo_ensayo == softwareEjecutar.grupo);
        // si tenemos aguna categoria de filtro
        // // ////console.log({categoria_kit_ensayos_filtered:this.categoria_kit_ensayos_filtered});
        if (this.categoria_kit_ensayos_filtered.length == 0) {
          this.sampleList.forEach(element => {
            element.categoria_kit_ensayo_id = null;
          });
        }
        // Mostrar u Ocultar
        this.nameCategoriaKitEnsayos = softwareEjecutar.name
      }
    },
    nopublicarMuestra() {
      this.sampleList.forEach((element) => {
        if (element.codesample == this.muestrasearch.response.muestras_id) {
          element.icon = 'mdi-close';
          element.color = 'red';
          element.codesample = '';
          element.muestra_idres = '';
          element.matriz_id = null;
          element.matriznombre = '';
          element.deteriorado = '';
          element.matriz = '';
          element.kit = 0;
        }
      });
      this.dialog = false;
    },
    publicarMuestra() {
      this.sampleList.forEach((element) => {
        if (element.codesample == this.muestrasearch.response.muestras_id) {
          element.icon = 'mdi-check-outline';
          element.color = 'gren';
          element.muestra_idres = this.muestrasearch.response.id;
          element.matriz_id = this.muestrasearch.muestra.id_matriz;
          element.matriznombre = this.muestrasearch.kitmatriz !== null ? this.muestrasearch.kitmatriz.kit : ''
          element.deteriorado = this.muestrasearch.muestra.deteriorante;
          element.matriz = this.muestrasearch.muestra.matriz;
          element.kit = this.muestrasearch.muestra.id_kit;
          element.info_muestra = this.muestrasearch.muestra;
          element.info_ensayo_muestra = this.muestrasearch.muestra;
        }
      });
      this.dialog = false;
    },
    async actualizaMuestra(item, event, tipo) {
      /*
            if (item.codesample !== '') {
                const info = {
                    id_muestra: item.codesample,
                    id_kit: item.kit,
                    deteriorado: item.deteriorado,
                }
                const update = await Service.apiToken('POST','update-sample',this.$ls.storage.token, info);
            }
            */

    },
    updatelist(item, event) {
      ////console.log({MATRIZ:item});
      this.sampleList.forEach(element => {
        if (element.pocillo === item.pocillo) {
          if (item.matriz_id != 12) {
            delete element.deteriorado;
            delete element.kit;
            delete element.matriznombre
          }
          const filtermat = this.matrices.filter((matrizlist) => matrizlist.id === event);
          element.matriz = filtermat[0].matriz;
        }
      });
    },
    async alertForSelectData(title){
      Swal.fire({
        title:title ,

        icon: 'warning',

      })
      //Swal.fire(i18n.t('analisis.no_medio_and_equipo'));
    },
    async buscarOT() {
      if((this.dataForm.kitPcr == null  || this.dataForm.equipoKit == null) && this.radios == 2){
        this.alertForSelectData(i18n.t('analisis.no_medio_and_equipo'));
        return;
      }
      if (this.dataForm.OT) {
        this.loadot = true;
        // buscar por bitacora lo llamamos a otra funcion
        if (this.radios == 3) {
         await this.buscarBitacora();
          return;
        }
        const info = {
          ot: this.dataForm.OT,
        }

        if (this.infoUser.externo == 0) {
          const OT = await Service.apiToken('POST', 'buscar-OT', this.$ls.storage.token, info);
          if (OT.response.length == 0) {
            this.snack.snackbar = true;
            this.snack.color = 'red';
            this.snack.colorbtn = '#fff',
              this.snack.txt = i18n.t('analisis.no_datos') + ' ' + this.dataForm.OT;
          } else {
            // VERIFICA QUE LA OT TENGA MUESTRAS CON LOS KITS SELECCIONADOS
            let found = false;
            // RECORRO LOS KITS
            this.kitPCR.map(kit => {
              // RECORRO LAS MUESTRAS
              if (!found) {
                OT.response[0].muestras_ots.map(muestra => {
                  ////console.log({LASMUESTRASOTSEARCH:muestra})
                  // LOS ENSAYOS DE ESTA MUESTRA
                  if (!found) {
                    muestra.ensayo_muestras.map((ensayoMuestra) => {
                      // LOS ENSAYOS DEL KIT
                      kit.id_ensayos.map((kitEnsayo) => {
                        if (kitEnsayo === ensayoMuestra.ensayo_id) {
                          found = true;
                        }
                      });
                    });
                  }
                })
              }
            })
            if (found) {
              this.infoOt.muestreador = OT.response[0].entregadapor;
              this.$refs.detalleOt.open(OT.response[0], this);
            } else {
              this.snack.snackbar = true;
              this.snack.color = 'red';
              this.snack.colorbtn = '#fff',
                this.snack.txt = i18n.t('analisis.no_datos') + ' ' + this.dataForm.OT;
            }
          }
        }
        this.loadot = false;
      }
    },
    aplicarmatriz(item) {
      this.sampleList.forEach(element => {
        if (element.checked == true && !element.codesample) {
          element.matriz_id = item.id;
        }
      });
    },
    aplicarKit(item) {
      this.sampleList.forEach(element => {
        if (element.checked == true && element.matriz_id == 12 && !element.codesample) {
          element.kit = item.id;
          element.matriznombre = item.kit;
        }
      });
    },
    aplicarDeterioro(item) {
      this.sampleList.forEach(element => {
        if (element.checked == true && element.matriz_id == 12 && !element.codesample) {
          element.deteriorado = item.id;
        }
      });
    },
    aplicarCategoriaKitEnsayos(items) {
      // ////console.log({items});
      const prefijo = items.prefijo
      const categoria_kit_ensayo_id = items.id

      this.sampleList.map(item => {
        // item.prefijo = null
        // item.categoria_kit_ensayo_id = null
        if (item.checked) {
          item.prefijo = prefijo
          item.categoria_kit_ensayo_id = categoria_kit_ensayo_id
        }
      })

      // ////console.log({'this.sampleList3':this.sampleList})
    },
    checkIFIsPool(item) {
      // Detectar el id de Pool:
      const pool_id = this.categoria_kit_ensayos.filter(item => item.nombre == 'Pool')[0].id;

      // Si el posillo tiene el prefijo 'POOL'
      // y la respuesta que devuelve es{
      //     Clase: 'cualquier numero'
      //     Muestra_outlier: "NO"
      // }
      // Entonces mostrar "POSITIVO" en la columna 'Resultado'
      // let pool = false;

      if (item.categoria_kit_ensayo_id == pool_id && !isNaN(item.clase) && item.outlier == 'NO') {
        return true;
      }
      else if (item.categoria_kit_ensayo_id == pool_id && item.outlier == 'SI') {
        return true;
      } else {
        return false;
      }
    },
    async analizarMuestras(muestras) {
      this.activeinput = 0;
      //////console.log({WWWWWW:muestras});
      let ensayoskit = [];
      this.kitPCR.forEach(element => {
        if (element.id == this.dataForm.kitPcr) {
          ensayoskit = element;
        }
      });


      this.sampleList.forEach((element) => {
        muestras.forEach(elementM => {
          if (element.pocillo == elementM.pocillo) {
            //buscamos el ensayo que se esta analizando de la lista
            let ensayoanalizar = [];

            if (elementM.muestra != null) {
              elementM.muestra.ensayo_muestras.forEach(ensayoslist => {
                if (ensayoskit.id_ensayos.includes(ensayoslist.ensayo_id)) {
                  ensayoanalizar = ensayoslist;
                }
              });
              ////console.log({ENSAYOANALIZARRR:ensayoanalizar});
              element.codesample = elementM.muestra.id;
              element.icon = 'mdi-check-outline';
              element.color = 'gren';
              // element.muestra_idres = elementM.muestra.ensayo_muestras[0].id;
              element.muestra_idres = ensayoanalizar.id;
              element.matriz_id = elementM.muestra.id_matriz;
              //element.matriz_id = elementM.muestra.control == null ? elementM.muestra.id_matriz : elementM.muestra.control;
              element.matriz =  elementM.muestra.matriz;

              // element.matriznombre = elementM.muestra.id_kit !== null  ? this.obtieneKit(elementM.muestra.id_kit) : ''
              element.matriznombre = elementM.muestra.control == null ? elementM.muestra.id_kit !== null ? this.obtieneKit(elementM.muestra.id_kit) : '' : elementM.muestra.control == '1' ? 'Control Positivo' : 'Control Negativo';
              // element.deteriorado = elementM.muestra.deteriorante;
              element.deteriorado = elementM.muestra.control == null ? elementM.muestra.deteriorante : '';
              element.matriz = elementM.muestra.matriz;
              // element.kit = elementM.muestra.id_kit;
              element.kit = elementM.muestra.control == null ? elementM.muestra.id_kit : 0;
              element.info_muestra = elementM.muestra;
              // element.info_ensayo_muestra = elementM.muestra.ensayo_muestras[0];
              element.info_ensayo_muestra = ensayoanalizar;
              element.ensayo_name = ensayoanalizar.ensayo.ensa_informe;
              element.checked = false;
              element.local = elementM.local;
              element.mixName = this.mixGlobal;
            } else {
              element.matriz_id = elementM.control;
              element.control = elementM.control;
              element.matriz = (elementM.control == '0') ? 'Control Negativo' : 'Control Positivo';
            }

          }
        });

      });

    },
    obtieneKit(kitid) {
      let kit = null;
      this.kitMatrices.forEach(element => {
        if (element.id == kitid) {
          kit = element.kit;

        }
      });
      ////console.log({ kitMatrices: this.kitMatrices });
      return kit;
    },
    volver() {
      if (this.inicioAnalisis) {
        Swal.fire({
          title: i18n.t('analisis.salir'),
          text: i18n.t('analisis.abandonar'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: i18n.t('analisis.aceptar'),
          cancelButtonText: i18n.t('analisis.cancelar')
        }).then((result) => {
          if (result.isConfirmed) {
            this.volverSeguro();
          }
        })
      } else {
        this.volverSeguro();
      }

    },
    volverSeguro() {
      this.cambiarStatusAnalisis(false);
      this.ocultarBtnPublicar = false;
      this.activarAnalisis = true;
      this.analizar = true;
      this.publicsample = false;
      this.dataForm = {};
      this.mixGlobal = null;
      let planilla = [];
      this.arrayinicial = [1];
      this.arraycontinua = [1,2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
      this.nadaApublicar = false;
      this.parametroIndefinido = [];
      this.muestrasNopublicadas = [];
      this.local = '';
      this.completedBitacora = null;
      this.warningsPublicar = [];
      this.newEquipo =  this.equipoKit
      for (let i = 1; i <= 96; i++) {
        let filaplanilla = {
          pocillo: i,
          nomMatriz: null,
          codesample: '',
          matriz_id: null,
          matriz: 'Otro',
          kit: null,
          Bac_1: null,
          Bac_2: null,
          Bac_3: null,
          Bac_4: null,
          Bac_5: null,
          prob_1: null,
          prob_2: null,
          prob_3: null,
          prob_4: null,
          prob_5: null,

          checked: false,
          icon: 'mdi-close',
          color: 'red',
          local: '',
          namePocillo: i + '',
          deteriorado: null,
          categoria_kit_ensayo_id: null,
          prefijo: null,
          ensayo_name:'',
          grupoEnsayo:'',
          mixId:null,
          mixName:'',
          prefijoKit:'',
          idbitacora:'',
          prefijo_control:'',
          ind_control:'',
        }
        planilla.push(filaplanilla)
      }

      this.sampleList = planilla;
      ////console.log({PLANILLALIMPIA:this.sampleList})
    },
    getclase(item, tmp = null) {

      let clase = '';
      // if(item.clase!=undefined){
      if (this.checkIFIsPool(item)) {
        clase = ' ';
      } else {
        if (isNaN(parseInt(item.clase))) {
          console.warn({getclase:item});
          const resultOmitir = ['Negativo', 'Vacio', 'Vacío', 'Inhibido']
          if (item.resultadofinal && !resultOmitir.includes(item.resultadofinal)) {
            clase = this.getRiesgo(item, (i18n.t('analisis.clase') + ' ' + item.clase), tmp)
          }

        } else {
          ////console.log(item.outlier);
          if (item.outlier == 'NO') {
            if (this.checkIFIsPool(item)) { // si el pocillo es pool no mostramos clase
              clase = '  ';
            } else { // AQUI VA 'CLASE'
              // clase = i18n.t('analisis.clase') + ' ' + item.clase
              clase = this.getRiesgo(item, (i18n.t('analisis.clase') + ' ' + item.clase), tmp)
            }
          }
          if (item.outlier == 'Gris') {
            // clase = i18n.t('analisis.zonagris') + ' ' + i18n.t('analisis.clase') + ' ' + item.clase
            // clase = i18n.t('analisis.zonagris')
            clase = this.getRiesgo(item, (i18n.t('analisis.clase') + ' ' + item.clase), tmp)
          }
        }
      }
      // if( tmp != null ){
      //     this.getRiesgo(item, clase)
      // }
      // this.resultado_getclase = clase

      return clase;

    },
    getRiesgo(item, clase, tmp) {

      const tmp_info_muestra = JSON.stringify(item.info_muestra)
      if (tmp_info_muestra == undefined) {
        return this.cat_riesgo[0].value; // bajo riesgo
      }

      const item_tmp = {
        'Bac': [],
        'prob_final': [],
        muestra_idres: item.muestra_idres,
        matriznombre: item.matriznombre,
        matriz_id: item.matriz_id,
        clase: item.clase,
        categoria_kit_ensayo_id: item.categoria_kit_ensayo_id,
      }

      Object.entries(item).forEach(([key, value]) => {
        if (key.includes("Bac_")) {
          if (value != null) {
            item_tmp.Bac.push(value)
          }
        }

        if (key.includes("prob_final_")) {
          if (value != null) {
            item_tmp.prob_final.push(value)
          }
        }
      });

      let parametro_con_riesgo = []

      // x MATRICES:
      switch (item.info_muestra.id_matriz) {
        case 6: // Ambientes
          // DE MOMENTO NO DISPONIBLE SU CODIGO
          break;

        case 8: // Manipuladores
          parametro_con_riesgo = this.AAA_configuracionSteriplex.data.filter(item2 => {
            return (
              item2.matriz_id == item.info_muestra.id_matriz &&
              item2.nivel1_id == item.info_muestra.condicion
            )
          })
          break;

        case 9: // Superficies
          parametro_con_riesgo = this.AAA_configuracionSteriplex.data.filter(item2 => {
            return (
              item2.matriz_id == item.info_muestra.id_matriz &&
              item2.nivel1_id == item.info_muestra.zona
            )
          })
          break;

        default: // 12 Alimentos  &&  7 Agua
          ////console.log({RIESHO:this.AAA_configuracionSteriplex.data})
          ////console.log({ITEM:item.info_muestra.id_matriz})
          parametro_con_riesgo = this.AAA_configuracionSteriplex.data.filter(item2 => {

            if (
              item2.matriz_id == item.info_muestra.id_matriz &&
              item2.nivel1_id == item.info_muestra.categoria_matriz_id &&
              (item2.nivel2_id == item.info_muestra.id_kit || item.info_muestra.id_kit == 0) && // item2.nivel2_id
              (item2.nivel3_id == item.info_muestra.subkit_id || item.info_muestra.subkit_id == 0) // item2.nivel3_id
            ) {

            }
            if(this.isProgram == true){
              return (
                item2.matriz_id == item.matriz_id

              )
            }else{
              return (
                item2.matriz_id == item.info_muestra.id_matriz &&
                item2.nivel1_id == item.info_muestra.categoria_matriz_id &&
                (item2.nivel2_id == item.info_muestra.id_kit || item.info_muestra.id_kit == 0) && // item2.nivel2_id
                (item2.nivel3_id == item.info_muestra.subkit_id || item.info_muestra.subkit_id == 0) // item2.nivel3_id
              )
            }
          })
          break;
      }

      if (parametro_con_riesgo.length == 0) {
        return this.cat_riesgo[0].value; // bajo riergo
      }

      let coincidenParametros = []
      parametro_con_riesgo.map(item3 => {
        item3.tb_configuracion_steriplex_parametros.filter(item2 => {
          if (item_tmp.Bac.includes(item2.patogeno_new.nombre)) {
            coincidenParametros.push(
              item2.patogeno_new.nombre
            )
          }
        })
      })

      if (coincidenParametros.length == 0) {
        return this.cat_riesgo[0].value; // bajo riesgo
      }

      coincidenParametros = [...new Set(coincidenParametros)]

      this.sampleList.map(item2 => {
        if (item2.pocillo == item.pocillo) {
          item2.coincidencias = coincidenParametros
        }
      })

      return this.cat_riesgo[1].value; //'Alto riesgo'
    },
    // getnameBacteria(nombreBac){
    getnameBacteria(nombreBac, item = null, tmp = null, pos = null) {
      console.log({getnameBacteria:nombreBac});
      let bacteria = nombreBac;
      if (item.resultadofinal) {
        //bacteria ='';
      }
      else {
        switch (nombreBac) {
          case 'Inhibido':
            bacteria = i18n.t('analisis.inhibido');
            // ////console.log('1',bacteria);
            break;
          case 'Vacio':case 'Vacío':
            console.log({getnameBacteria_vacio:  item.clase == 'empty_reprocess'});
            bacteria = item.clase == 'empty_reprocess' ? 'Empty, repeat reaction' : i18n.t('analisis.vacio');
            // ////console.log('2',bacteria);
            break;
          case 'Negativo':
            bacteria = i18n.t('analisis.negativo');
            // ////console.log('3',bacteria);
            break;
          case 'Microorganismo no registrado':
            bacteria = i18n.t('analisis.microorganismonoregistrado');
            // ////console.log('3',bacteria);
            break;
          case 'Positivo':
            bacteria = i18n.t('analisis.positivo');
            // ////console.log('4',bacteria);
            break;
          case 'Error': case 'Error_Perfil':
            bacteria = i18n.t('analisis.repetirReaccion');
            break;
          case 'Gris':
            bacteria = i18n.t('analisis.reaccionGris');
            break;
          default:
            if (!!nombreBac) {
              bacteria = nombreBac;
              // ////console.log('5',bacteria);
            } else {
              if (item.resultadofinal) {
                // ////console.log({final:item.resultadofinal});
                // bacteria = item.resultadofinal
              }
            }
            break;
        }
      }
      // ////console.log({
      //     pocillo: item.pocillo,
      //     bacteria,
      //     tmp
      // });
      //console.log(bacteria);
      return bacteria;
    },
    updatematriz(item, event) {
      this.sampleList.forEach(element => {
        if (element.pocillo === item.pocillo) {

          const filtermat = this.kitMatrices.filter((matrizlist) => matrizlist.id === event);
          element.matriznombre = filtermat[0].kit;
        }
      });
    },
    gettop: function () {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 20)
    },
    getOrigen(data) {
      let response = i18n.t('analisis.otro');
      if (data.info_muestra) {
        switch (data.info_muestra.mp_pt) {
          case 'mp': case 'mprima': response = 'Materia prima'; break;
          case 'Pdeterminado': case 'Pterminado': response = 'Producto terminado'; break;
          case null:
            if (data.matriz_id == 9) {
              response = data.matriz;
            }
            else if (data.matriz_id == 7) {
              response = data.matriz;
            }
            else {
              response = i18n.t('analisis.otro');
            }
            break;
          default: response = i18n.t('analisis.otro'); break;
        }
      }
      else {
        switch (data.matriz_id) {
          case 9: response = data.matriz; break;
          case 7: response = data.matriz; break;
          default: response = i18n.t('analisis.otro'); break;
        }
      }
      return response;
    },
    disableSelect(item) {
      let disabled = false;
      if (item.info_muestra || item.matriz_id != 12) {
        return true;
      }
    },
    limpiarplanilla() {
      ////console.log({ dataForm: this.dataForm });
      let filas = 16;
      let j = 1;
      let abc = [''];
      ////console.log({ENTRAAQUI:this.dataForm.equipoKit});
      if (this.dataForm.equipoKit == 2  || this.dataForm.equipoKit == 3) {
        filas = 12;
        abc = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
      }

      this.sampleList = [];
      let planilla = [];
      ////console.log({abc,filas});
      //let defaultMedio = (this.categoria_kit_ensayos_filtered.length == 0) ? null : 1;
      abc.forEach((index) => {
        for (let i = 1; i <= filas; i++) {
          planilla.push({
            pocillo: j,
            nomMatriz: null,
            codesample: '',
            matriz_id: null,
            matriz: 'Otro',
            kit: null,
            Bac_1: null,
            Bac_2: null,
            Bac_3: null,
            Bac_4: null,
            Bac_5: null,
            prob_1: null,
            prob_2: null,
            prob_3: null,
            prob_4: null,
            prob_5: null,
            checked: false,
            icon: 'mdi-close',
            color: 'red',
            local: '',
            namePocillo: ((abc.length > 1) ? index + i : i + ''),
            deteriorado: null,
            prefijo: null,
            categoria_kit_ensayo_id: null,  //null
            grupoEnsayo:'',
            ensayo_name:'',
            mixId:null,
            mixName:'',
            prefijoKit:'',
            idbitacora:'',
            prefijo_control:'',
            ind_control:'',
            nombre: '',
          })
          j++;
        }
      });
      // ordenamos
      ////console.log({planilla});
      /*planilla.sort(function(a, b) {
        let numA = parseInt(a.namePocillo.substring(1));
        let numB = parseInt(b.namePocillo.substring(1));
        let comp = numA - numB;
        if (comp !== 0) {
          return comp;
        }
        else {
          // ////console.log({a});
          return true;//a[0].localeCompare(b[0].namePocillo);
        }
      });*/
      planilla.sort(function(a, b) {
        var numeroA = parseInt(a.namePocillo.slice(1));
        var numeroB = parseInt(b.namePocillo.slice(1));

        var letraA = a.namePocillo.slice(0, 1);
        var letraB = b.namePocillo.slice(0, 1);

        if (numeroA == numeroB) {
          return letraA.localeCompare(letraB);
        }
        return numeroA - numeroB;
      });

      this.sampleList = planilla;
    },
    getclasebac(item, pos) {
      let result = '';

      switch (item[`Bac_${pos}`]) {
        case ' ':
        case ' ':
          result = '';
          break;
        case 'Inhibicion':
          result = '';
          break;
        default:
          result = (item[`Prob_${pos}`] == true) ? '- Positivo' : '';
          break
      }


      const nombreBac = item[`Bac_${pos}`]
      if (item.coincidencias.includes(nombreBac)) {
        // ////console.log('---------------C')
        // // ////console.log(tmp)
        // ////console.log({nombreBac})
        // ////console.log({'item.coincidencias':item.coincidencias})
        // ////console.log({item})
        // ////console.log('--------------/C')
        result = 'colorear_en_rojo'
      }
      // ////console.log({result})

      return result;
    },
    async activaItemspocillos(item) {
      this.maxFiles = 0;
      if(this.radios == 4){
        this.maxFiles = 1;
      }
      /// filtramos el detalle del equipo
      // let detalleEquipo = this.equipoKit.filter(equipo => equipo.id == item)
      switch (item) {
        case 1:
          this.accept_txt = "text/csv";
          this.accept = /([a-zA-Z0-9\s_\\.\-\(\):])+(.csv)$/ig;
          this.disabledFile = false;
          this.disabledBtnOtSearch = false;

          break;
        case 2:
          this.accept_txt = "text/plain";
          this.accept = /([a-zA-Z0-9\s_\\.\-\(\):])+(.txt)$/ig;
          this.disabledFile = false;
          this.disabledBtnOtSearch = false;

          break;
        case 3:
          this.accept_txt = "text/plain";
          this.accept = /([a-zA-Z0-9\s_\\.\-\(\):])+(.csv)$/ig;
          this.disabledFile = false;
          this.disabledBtnOtSearch = false;
          break;
        default:
          this.disabledFile = true;
          this.disabledBtnOtSearch = true;

      }
      let infoEquipo = this.equipoKit.filter(function (element) {
        return element.id == item;
      });
      this.dataForm.file = null;
      this.activarAnalisis = true;
      this.dataForm.OT = null;
      this.limpiarplanilla();
      /*
            if(item == 1){
                await this.limpiarplanilla();
            }else{
                await this.activaItemspocillosAbecedario();
            }*/

    },

    async activaItemspocillosAbecedario() {

      let abc = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']

      let j = 0;
      let planilla = [];
      abc.forEach(function (index) {
        for (let i = 1; i <= 12; i++) {
          let filaplanilla = {
            pocillo: index + i,
            nomMatriz: null,
            codesample: '',
            matriz_id: null,
            matriz: 'Otro',
            kit: null,
            Bac_1: null,
            Bac_2: null,
            Bac_3: null,
            Bac_4: null,
            Bac_5: null,
            prob_1: null,
            prob_2: null,
            prob_3: null,
            prob_4: null,
            prob_5: null,
            checked: false,
            icon: 'mdi-close',
            color: 'red',
            local: '',
            namePocillo: index + i,
            deteriorado: null,
            ensayo_name:'',
            grupoEnsayo:'',
            mixId:null,
            mixName:'',
            prefijoKit:'',
            idbitacora:'',
            prefijo_control:'',
            ind_control:'',

          }
          planilla.push(filaplanilla);
          j++;
        }
      });
      // ////console.log("sampleList3")
      this.sampleList = planilla;
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}-${('0' + (current.getMonth() + 1)).slice(-2)}-${('0' + current.getDate()).slice(-2)}`;
      return date;
    },
    currentTime() {
      const today = new Date();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + ('0' + today.getSeconds()).slice(-2);
      return time;
    },

    // NUEVAS FUNCIONES 2022 TXA-GLOBAL
    async crearTablaequipo(pocillos = 16) {
      let filas = pocillos;
      let j = 1;
      let abc = ['']

      if (this.dataForm.equipoKit == 2) {
        filas = 12;
        abc = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
      }
      let planilla = [];
      abc.forEach(function (index) {
        for (let i = 1; i <= filas; i++) {
          let filaplanilla = {
            pocillo: j,
            nomMatriz: null,
            codesample: '',
            matriz_id: null,
            matriz: 'Otro',
            kit: null,
            Bac_1: null,
            Bac_2: null,
            Bac_3: null,
            Bac_4: null,
            Bac_5: null,
            prob_1: null,
            prob_2: null,
            prob_3: null,
            prob_4: null,
            prob_5: null,
            checked: false,
            icon: 'mdi-close',
            color: 'red',
            local: '',
            namePocillo: ((abc.length > 1) ? index + i : i + ''),
            deteriorado: null,
            ensayo_name:'',
            grupoEnsayo:'',
            mixId:null,
            mixName:'',
            prefijoKit:'',
            idbitacora:''
          }
          planilla.push(filaplanilla)
          j++;
        }
      });
      // ////console.log("sampleList4")
      this.sampleList = planilla;
    },
    async getFormatresult(item, posicion) {
      // ////console.log(posicion,item);
      let resutItem = {
        clase: '', // el item que ira en primer lugar
        resultado: 0, // 0 es negativo, 1 es positivo define que icono dibujar
        resultados: [] // resto de los microorganismo con sus resultados
      }
      // Negativo,(1-25),zona gris
      switch (item.clase) {
        case 'Negativo':
          resutItem.clase = item.clase;
          resutItem.resultado = 0;
          if (item.outlier == 'SI') {
            resutItem.clase = item.Bac_1;
            resutItem.resultado = 0;
          }
          break;
        case 'Vacio':
          resutItem.clase = i18n.t('analisis.vacio');
          resutItem.resultado = 0;
          break;
        default:
          resutItem.clase = 'Clase ' + item.clase;
          resutItem.resultado = 1;
          if (item.outlier == 'Gris') {
            resutItem.clase = i18n.t('analisis.zonagris') + '-' + i18n.t('analisis.clase') + item.clase;
            resutItem.resultado = 1;
          }
          break;
      }
      resutItem.resultados = await this.getResultadositem(item);
      // ////console.log(resutItem);
    },
    getResultadositem(item) {
      let result = [];
      for (let index = 0; index < 26; index++) {
        if (item[`Bac_${index}`]) {
          result.push(
            {
              resultado: item[`Bac_${index}`] + '-' + item[`Bac_${index}`] + ' %'
            }
          )
        }

      }
      return result;
    },
    getPrefijonombre(item) {
      let prefijo = '--';
      // // ////console.log({item:item,categoria_kit_ensayos:this.categoria_kit_ensayos});
      if (item.categoria_kit_ensayo_id) {
        const pool_id = this.categoria_kit_ensayos.filter(itemkit => itemkit.id == item.categoria_kit_ensayo_id);
        // // ////console.log({pool:pool_id});
        if (pool_id.length > 0) {
          prefijo = pool_id[0].nombre;
        }
      }
      return prefijo;
    },
    mostrarClase(item) {
      let clasePocillo = '';
      let infoEnsayo = this.kitPCR.filter(kit => kit.id == this.dataForm.kitPcr);
      // ////console.log({ infoEnsayo });
      if (item.clase && infoEnsayo[0]) {
        if (infoEnsayo[0].software.mostrarClase) {
          clasePocillo = i18n.t('analisis.clase') + ' ' + item.clase;
        }
      }
      return clasePocillo;
    },
    // FIN NUEVAS 2022 TXA-GLOBAL
    async buscarBitacora() {

      this.activeinput = 0;
      let softwareEjecutar = this.kitPCR.filter(kit => kit.id == this.dataForm.kitPcr);
      this.loadingResponse = true;
      const Bitacora = await Service.apiToken('POST', 'buscar-bitacora', this.$ls.storage.token,
        {
          idbitacora: this.dataForm.OT,
          tipeLogbook: this.tipoBitacora
        });
      console.log({ Bitacora:Bitacora });
      if (!Bitacora || Bitacora.response.length == 0) {
        switch (parseInt(Bitacora.idbitacora)) {
          case 123456789: // Bactiria
            this.maxFiles = 1;
            this.equipos = {
              'chaibio': 1,
              'aria': 1,
              'biorad': 1
            };

            this.setDefaultbitacora(parseInt(Bitacora.idbitacora));
            this.loadot = false;
            break;
          case 987654321: // HyL
            this.maxFiles = 1;
            this.equipos = {
              'chaibio': 1,
              'aria': 1,
              'biorad': 1
            };
            this.setDefaultbitacora(parseInt(Bitacora.idbitacora));
            this.loadot = false;
            break;
          case 1234554321: // Zigoplex
            this.maxFiles = 1;
            this.equipos = {
              'chaibio': 1,
              'aria': 1,
              'biorad': 1
            };
            this.setDefaultbitacora(parseInt(Bitacora.idbitacora));
            this.loadot = false;
            break;
          case 99999999: // bact lacto y salsas
            this.maxFiles = 1;
            this.equipos = {
              'chaibio': 1,
              'aria': 1,
              'biorad': 1
            };
            this.setDefaultbitacora(parseInt(Bitacora.idbitacora));
            this.loadot = false;
            break;
          case 88888888: // htl lacto y salsas
            this.maxFiles = 1;
            this.equipos = {
              'chaibio': 1,
              'aria': 1,
              'biorad': 1
            };
            this.setDefaultbitacora(parseInt(Bitacora.idbitacora));
            this.loadot = false;
            break;
          default:
            break;
        }
        this.snack.snackbar = true;
        this.snack.color = 'red';
        this.snack.colorbtn = '#fff',
          this.snack.txt = i18n.t('analisis.no_datos_bitacora') + ' ' + this.dataForm.OT;
        this.loadot = false;
      } else {
        this.loadot = false;
        // recorre los datos para llenar la info de los pocillos

        this.overwritePublications = true;         
        if (Bitacora.statusBitacora === 2 || Bitacora.statusBitacora === 1 ) {
           new Promise(async (resolve, reject) => {
                 await this.swalConfirmation(false, this.$t('analisis.are_you_sure_overwrite_them'), this.$t('analisis.yes_overwrite'), this.$t('analisis.cancelar'), resolve, reject,'ok','' );
           }).then(async (response) => {
               await this.setSamplesTable(Bitacora, true);
               this.assignNeededFiles(Bitacora);
             this.activarAnalisis = false;
           }).catch(async (error) => {
             this.dataForm.OT = null;
             this.activarAnalisis = true;
             this.dataForm.file = null;
             this.maxFiles = 0;
             this.equipos = null;
             this.$forceUpdate();
             this.limpiarplanilla();
           });
           }else{
             await this.setSamplesTable(Bitacora, true);
                this.assignNeededFiles(Bitacora);
          this.activarAnalisis = false;
        }
        //this.setSamplesTable(Bitacora, true);
      }
      this.loadingResponse = false;

    },
    assignNeededFiles(Bitacora){
      let programa = this.equipoKit.find((el) => {
        return el.id === this.dataForm.equipoKit;
      })
      programa = programa.equipo.toLowerCase();

      this.maxFiles = Bitacora.docsRequired[programa];
      this.equipos = Bitacora.docsRequired;
    },
    async equipoDisabled(pocillos){
      let equiposelected = this.equipoKit.filter(kit => kit.id == this.dataForm.equipoKit);
      if(equiposelected[0].pocillos < pocillos){
        this.alertForSelectData(i18n.t('analisis.no_equipo_compatible'));
        this.volver();
      }

    },
    async setSamplesTable(Bitacora , overwrite){

      ////console.log({SAMPLESLISTBUSCAR:this.equipoKit})
      this.equipoDisabled(Bitacora.response.length);
      let getEnsayoKit =  this.kitPCR.find(pcr => pcr.id_ensayos.includes(Bitacora.response[0].ensayo_id));
      //this.dataForm.equipoKit =  Bitacora.response[0].id_equipo;
      this.dataForm.kitPcr = getEnsayoKit?getEnsayoKit.id:null;
      this.prefijoProgram = this.getPrefijo(Bitacora.response[0].programa_id);
      this.isProgram = (Bitacora.response[0].programa_id == 0 )?false:true;
      this.grupoEnsayo = Bitacora.response[0].grupo_ensayo_id;
      //this.activaItemspocillos(Bitacora.response[0].id_equipo);
      Bitacora.response.forEach(element => {
        if(overwrite == true){

          this.sampleList.map((muestra) => {
            ////console.log({POZO:element.sn_pozo, POZO2:muestra.namePocillo})
            let equiposelected = this.equipoKit.filter(kit => kit.id == this.dataForm.equipoKit);
            ////console.log({POZO:equiposelected})
            let pocilloValidar = element.sn_pozo;
            if(equiposelected[0].pocillos == 16){
              pocilloValidar = element.pocillo_num;
            }
            if (pocilloValidar == muestra.namePocillo) {
              if( element.int_control == null){
                muestra.matriz_id = element.id_matriz;
                muestra.matriz = element.matriz;
              }else{
                muestra.matriz_id = element.int_control;

              }
              muestra.deteriorado = element.deteriorante;

              muestra.info_muestra={
                ot_id:element.ot_id,
                id:element.muestra_id,
                categoria_matriz:{
                  nombre:element.categoria_matriz,
                  valor:element.valor_cat_matriz
                },
                sub_categoria_kit: {
                  nombre: element.subkits,
                  codigo_steriplex: element.codigo_steriplex
                }
              }
              muestra.local = {
                nombre_local: element.nombre_local
              }
              if(element.muestra_id == 0){
                muestra.codesample=this.getControlesTipe(element.int_control)[0]
              }else{
                muestra.codesample = element.muestra_id;
              }

              muestra.muestra_idres = element.ensayo_muestra_id;
              muestra.ensayo_name = element.ensa_informe;
              muestra.ensayo_id = element.ensayo_id;
              muestra.grupoEnsayo = element.grupo_ensayo_id;
              muestra.idPrograma = element.programa_id;
              muestra.categoria_kit_ensayo_id = this.getMedio(element.id_medio)[0];
              muestra.nombre = this.getMedio(element.id_medio)[1];
              muestra.mixName = element.mixname;
              muestra.mixId = element.mixId;
              muestra.prefijoKit= (Bitacora.response[0].programa_id != 0)?this.getPrefijo(element.programa_id):null;
              muestra.idbitacora= element.id_config_bitacora;
              muestra.info_muestra.descripcion = (element.muestra_name == null)?this.getControlesTipe(element.int_control)[1]:element.muestra_name;
              muestra.ind_control = element.int_control;
              muestra.parametros = element.parametros;
              muestra.publicar = (element.publicado==1) ? false :true;
              muestra.tipo_semicuantificado = element.tipo_semicuantificado;
              muestra.txtRange = element.txtRange ? element.txtRange : null;
            }
            else{

              muestra.idPrograma = element.programa_id
            }
          })
        }else{
          if(!Bitacora.publicatedSamples.includes(element.muestra_id)){
            this.sampleList.map((muestra) => {
              let equiposelected = this.equipoKit.filter(kit => kit.id == this.dataForm.equipoKit);
              ////console.log({POZO:equiposelected})
              let pocilloValidar = element.sn_pozo;
              if(equiposelected[0].pocillos == 16){
                pocilloValidar = element.pocillo_num;
              }

              if (pocilloValidar == muestra.namePocillo) {
                if( element.int_control == null){
                  muestra.matriz_id = element.id_matriz;
                  muestra.matriz = element.matriz;
                }else{
                  muestra.matriz_id = element.int_control;
                }
                muestra.deteriorado = element.deteriorante;
                muestra.info_muestra={
                  ot_id:element.ot_id,
                  id:element.muestra_id,
                  categoria_matriz:{
                    nombre:element.categoria_matriz,
                    valor:element.valor_cat_matriz
                  },
                  sub_categoria_kit: {
                    nombre: element.subkits,
                    codigo_steriplex: element.codigo_steriplex
                  }
                }
                muestra.local = {
                  nombre_local: element.nombre_local
                }
                if(element.muestra_id == 0){
                  muestra.codesample=this.getControlesTipe(element.int_control)[0]

                }else{
                  muestra.codesample = element.muestra_id;
                }

                muestra.muestra_idres = element.ensayo_muestra_id;
                muestra.ensayo_name = element.ensa_informe;
                muestra.grupoEnsayo = element.grupo_ensayo_id;
                muestra.idPrograma = element.programa_id;
                muestra.categoria_kit_ensayo_id = this.getMedio(element.id_medio)[0];
                muestra.nombre = this.getMedio(element.id_medio)[1];
                muestra.mixName = element.mixname;
                muestra.mixId = element.mixId;
                muestra.prefijoKit= (Bitacora.response[0].programa_id != 0)?this.getPrefijo(element.programa_id):null;
                muestra.idbitacora= element.id_config_bitacora;
                muestra.info_muestra.descripcion = (element.muestra_name == null)?this.getControlesTipe(element.int_control)[1]:element.muestra_name;
                muestra.ind_control = element.int_control;
                muestra.parametros = element.parametros;
                muestra.publicar = (element.publicado==1) ? false :true;

              }
              else{
                muestra.idPrograma = element.programa_id
              }

            })
          }
        }

      });

    },

    getControlesTipe(id_control){
      ////console.log({CONTROLES:id_control});
      let controlType = '';
      let controlPrefijo = '';
      switch(id_control){
        case 0:

          controlType = 'Control -'
          controlPrefijo = 'NC'
          break
        case 1:
          controlType = 'Control +'
          controlPrefijo = 'PC'
          break
        case 2:
          controlType = i18n.t('analisis.cePositivo');
          controlPrefijo = 'PEC'
          break
        case 3:
          controlType = i18n.t('analisis.ceNegativo');
          controlPrefijo = 'NEC'
          break
        case 4:
          controlType = i18n.t('analisis.cMedio');
          controlPrefijo = 'PMC '
          break
        case 5:
          controlType = i18n.t('analisis.cnMedio');
          controlPrefijo = 'NMC '
          break
        default:
          controlType = ''
          controlPrefijo = ''
          break;

      }
      ////console.log({CONTROLESreturn:controlType});

      return [controlPrefijo,controlType];

    },
    //UNSET VARIABLES TO CHANGE RADIO
    //@ALAN AVILA
    async changeRadios(event) {
      ////console.log({'entra':event});

      //////console.log('entra');

      this.dataForm.kitPcr = null;
      this.dataForm.equipoKit = null;
      this.dataForm.OT = null;
      let existChanges = 0;
      this.isProgram = false;


      if(event == 2){
        this.disabledBtnOtSearch = true;
      }else{
        this.disabledBtnOtSearch = false;
      }

      this.sampleList.forEach(element => {
        if (element.codesample != '') {
          existChanges = 1;
        }
      });
      if (existChanges === 1) {
        new Promise(async (resolve, reject) => {
          this.swalConfirmation(true, this.$t('analisis.continue_advance_deleted'), this.$t('continue'),'',resolve,reject);
        }).then(async (response) => {
          if(response){

          }
          this.activeinput = 1;
          this.sampleList.forEach(element => {
            this.dataForm.OT = null;
            element.codesample = '';
            element.checked = [];
            element.categoria_kit_ensayo_id = [];
            element.matriz_id = [];
            element.kit = [];
            element.deteriorado = [];
            element.ensayo_name = '';
            this.disabledFile = true;
            this.dataForm.file = null;
            this.mixName = null;

          });
        }).catch((error) => {

        });


        //let confirm = this.swalConfirmation('Si continua  se eliminara el avance', 'Continuar');

      }

    },
    //sweet alert function to confirmation
    //@ALAN AVILA
    swalConfirmation(cancelButton=false,title, confirmButtonText, denyButtonText, resolve, reject = null, resolveText = 'ok', text='') {

      let response = null;
      Swal.fire({
        title: title,
        text: text,
        showDenyButton: denyButtonText != '' ? true : false,
        showCancelButton: cancelButton,
        icon: 'warning',
        confirmButtonText: confirmButtonText,
        denyButtonText: denyButtonText != '' ? denyButtonText : '',

      }).then((result) => {
        if (result.isConfirmed) {
          resolve(resolveText);
        } else if (result.isDenied) {

          reject('cancelado');
        }

      })
      return response;
    },
    //OBTIENE EL MEDIO MEDIANTE EL ID_MEDIO
    getMedio(id_medio){
      //console.warn({LOSMEDIOS:this.categoria_kit_ensayos});
      let medioA = '-';
      let findMedio =  this.categoria_kit_ensayos.find(medio => medio.id == id_medio);
      if(findMedio){

        medioA = [ findMedio.id,findMedio.nombre];
      }
      return medioA;


    },
    async getEnsayosOrProgram(){
      const info = {
        datoenv: 1,
      };
      const datosUser = await Service.apiToken('POST', 'get-ensayosorprogram', this.$ls.storage.token, info);
      ////console.log({ENSORPROG:this.tipoBitacora});
      let kit = [];

      datosUser.programas.forEach(element => {
        kit.push({
          id: element.id,
          name: element.programa,
        })

      });

      this.programs = kit;


    },

    getPrefijo(id_programa){
      let programaPrefijo = [];
      programaPrefijo = this.programs.find(kit => kit.id == id_programa);
      return programaPrefijo.name;
    },
    async getMix(ensayo){
      const info = {
        ensayo: ensayo,
      };
      const mixs = await Service.apiToken('POST', 'get-mixs', this.$ls.storage.token, info);
      this.mixGlobal = mixs.data[0][0].mixname ;
      //////console.log({THISMIX:mixs.data[0][0].mixname});
    },
    enableResultEdit(data){
      ////console.log({data});
      this.setenableEditresult(!data)
    },
    checkAllpublish(event){
      if (this.checkedAllpublish === true) {
        this.sampleList.forEach((element) => {
          element.publicar = true;
        });
      } else {
        this.sampleList.forEach((element) => {
          element.publicar = false;
        });
      }
    },
    setDefaultbitacora(idbitacora){
      let programa = 2;
      let mixname = null;
      let mixId = null;
      let matriz = 'Otro';
      let categoriaMedio = null;
      let prefijo = null;
      switch (idbitacora) {
        case 123456789: //bacterias
          this.dataForm.kitPcr = 5;
          this.grupoEnsayo = 457;
          mixname = 'SP12-M1';
          mixId = 10;
          break;
        case 987654321: //HyL
          this.dataForm.kitPcr = 10;
          this.grupoEnsayo = 458;
          mixname = 'SP22-M1';
          mixId = 11;
          break;
        case 99999999: //bacterias lacto salsas
          this.dataForm.kitPcr = 5;
          this.grupoEnsayo = 457;
          mixname = 'SP12-M1';
          mixId = 10;
          matriz = 'salsas';
          categoriaMedio = 7;
          prefijo = 'Lacto';
          break;
        case 88888888: //HyL lacto salsas
          this.dataForm.kitPcr = 10;
          this.grupoEnsayo = 458;
          mixname = 'SP22-M1';
          mixId = 11;
          matriz = 'salsas';
          categoriaMedio = 7;
          prefijo = 'HyL';
          break;
        default:
      }
      console.log(idbitacora);
      console.log(this.kitPCR);
      console.log(this.dataForm);
      //this.dataForm.kitPcr = 10;
      this.isProgram = false;
      //this.grupoEnsayo = 458;
      this.prefijoProgram = 'Spoilage';
      this.sampleList.map((muestra) => {
        muestra.grupoEnsayo = this.grupoEnsayo;
        muestra.idPrograma = programa;
        muestra.mixName = mixname;
        muestra.mixId = mixId;
        muestra.prefijoKit= 'Spoilage';
        muestra.idbitacora=0;
        muestra.matriz= matriz;
        muestra.prefijo= prefijo;
      });
      console.log({samplelist:this.sampleList});
    },
    analyzeIsDisabled(){
      console.log({dataform:this.dataForm});
      //return !(this.dataForm.equipoKit !== null && this.selectedFiles !== null);
      return !(this.dataForm.equipoKit !== null && this.selectedFiles.length >0 );
    },
    filterProgram(){
      const items = this.kitPCR.filter((item) => {
        return parseInt(item.programa_id) === 1;
      });
      return items;
    },
    filterEq(){
      const items = this.newEquipo.filter((item) => {
        return parseInt(item.id) === 2 || parseInt(item.id) === 3;
      });
      return (this.radios === 4) ? items : this.newEquipo;
    },
    getTipo(item){
      console.log({getTipo:item});
      if(item.txtRange){
        return item.txtRange;
      }
      if(parseInt(item.tipo_semicuantificado) === 0){
        return this.$t('analisis.global');
      }
      return "";
    },
    showTextWarning(item){
      let txtError = '';
      /*return this.$t('analisis.el_param') + '<strong>'+ item.parametro + '</strong>' + this.$t('analisis.el_param_txt1') +
     '<strong>' + item.muestra + '</strong>' + this.$t('analisis.el_param_txt2');*/
      switch (item.reason){
        case 'EMPTY_ENSAYO_MUESTRA':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          break;
          case 'NOT_FOUND_ASSAY':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
            break;
          case 'IS_PUBLISH':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
            break;
          case 'NOT_FOUND_SAMPLE_BITACORA':
            txtError = `WARN Pocillo: ${item.pocillo}, bitacoraId: ${item.bitacoraId}, Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
            break;
          case 'CANNOT_DELETED_RESULTS_PREVIOUS_ASSAY_STERIPLEX':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
            break;
          case 'CANNOT_DELETED_PARAMETERS_ASSAY_STERIPLEX':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
            break;
          case 'CANNOT_DELETED_PRERESULTS_ASSAY_STERIPLEX':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
            break;
          case 'NOT_FOUND_PARAMETERS_ASSAY':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
            break;
          case 'NOT_FOUND_PARAMETER':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'NOT_ASING_PARAMETER_CUMPLE':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_REACTIVE_PARAM_MUESTRA_OT':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_CREATE_PARAM_MUESTRA_OT':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_DELETED_PRERESULT':
            txtError = `WARN Pocillo: ${item.pocillo}:  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_CREATE_PRERESULT':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_FIND_PRERESULTS_COMBINATION':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_UPDATE_PRERESULTS_COMBINATION_ERROR':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'INCONGRUENT_COMBINATION_RESULTS':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_UPDATE_PRERESULTS_COMBINATION_REPROCESS_R1':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'ONLY_REPROCESS_R1':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_UPDATE_PRERESULTS_COMBINATION_REPROCESS_R2':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'ONLY_REPROCESS_R2':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_UPDATE_PRERESULTS_COMBINATION_REPROCESS_ALL':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'REPROCESS_ALL':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
        case 'REPROCESS_RANGES':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          break;
        case 'REPROCESS_GLOBAL':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          break;
          case 'WAITING_RESULTS_OTHER_RANGES':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_UPDATED_NO_COMBINATION_REPROCESO':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'REPROCESS_PARAMETER':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_DESTROY_RESULT_PREVIOUS':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_PUBLISH_POSITIVE_RESULT':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_CREATE_RESULT':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
            break;
          case 'CANNOT_UPDATE_TO_PUBLISH_RESULTS_ENSAYO':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
            break;
          case 'CANNOT_UPDATE_END_PUBLICATION_ENSAYO_MUESTRA':
            txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
            break;
          case 'CANNOT_UPDATE_ESTATUS_BITACORA':
            txtError = `WARN Bitacora_id: ${item.bitacoraId} REASON: ${item.reason}`;
            break;
      }
      return txtError;
    },
    checkShowResultSteriplex(item,v){
      let show = item[`Bac_${v}`] && item[`Bac_${v}`] != 'NA';
      console.warn({checkShowResultSteriplex: item[`Bac_${v}`], show, item, v })
      return show;
    }
  },


};
</script>
